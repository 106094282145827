import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);




const routes = [
  {
    path: "/login",
    name: "Login",
    meta: { ja: "ログイン" },
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/forgotPassword",
    name: "ForgotPassword",
    meta: { ja: "パスワード忘れた" },
    component: () => import("../views/ForgotPassword.vue"),
  },
  {
    path: "/resetPassword",
    name: "ResetPassword",
    meta: { ja: "リセットパスワード" },
    component: () => import("../views/ResetPassword.vue"),
  },
  {
    path: "/register",
    name: "ApplyingMerchant",
    meta: { ja: "申請中加盟店" },
    component: () => import("../views/ApplyingMerchant.vue"),
    beforeEnter: (to, from, next) => {
      if (localStorage.mrchnt_tkn) {
        next({ name: "Login" });
      } else {
        next();
      }
    },
  },
  {
    path: "/",
    name: "OrderReceived",
    meta: { ja: "受注リスト" },
    component: () => import("../views/dashboard/OrderReceived.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.mrchnt_tkn) {
        next({ name: "Login" });
      } else {
        next();
      }
    },
  },
  {
    path: "/dashboard/processing",
    name: "Processing",
    meta: { ja: "対応中" },
    component: () => import("../views/dashboard/Processing.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.mrchnt_tkn) {
        next({ name: "Login" });
      } else {
        next();
      }
    },
  },
  {
    path: "/dashboard/completed",
    name: "Completed",
    meta: { ja: "対応済" },
    component: () => import("../views/dashboard/Completed.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.mrchnt_tkn) {
        next({ name: "Login" });
      } else {
        next();
      }
    },
  },

  // HIDE v1
  // {
  //   path: "/items",
  //   name: "ItemList",
  //   meta: { ja: "商品一覧" },
  //   component: () => import("../views/ItemList.vue"),
  //   beforeEnter: (to, from, next) => {
  //     if (!localStorage.mrchnt_tkn) {
  //       next({ name: "Login" });
  //     } else {
  //       next();
  //     }
  //   },
  // },
  {
    path: "/items/:id/detail",
    name: "ItemListDetail",
    meta: { ja: "商品詳細" },
    component: () => import("../views/ItemListDetail.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.mrchnt_tkn) {
        next({ name: "Login" });
      } else {
        next();
      }
    },
  },
  {
    path: "/itemStock",
    name: "ItemStock",
    meta: { ja: "在庫管理" },
    component: () => import("../views/ItemStock.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.mrchnt_tkn) {
        next({ name: "Login" });
      } else {
        next();
      }
    },
  },
  {
    path: "/orders",
    name: "Orders",
    meta: { ja: "注文管理" },
    component: () => import("../views/Orders.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.mrchnt_tkn) {
        next({ name: "Login" });
      } else {
        next();
      }
    },
  },
  {
    path: "/order/:id",
    name: "OrderDetail",
    meta: { ja: "注文詳細" },
    component: () => import("../views/OrderDetail.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.mrchnt_tkn) {
        next({ name: "Login" });
      } else {
        next();
      }
    },
  },
  {
    path: "/sales/orderDetails",
    name: "SalesOrderDetails",
    meta: { ja: "注文詳細" },
    component: () => import("../views/SalesOrderDetails.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.mrchnt_tkn) {
        next({ name: "Login" });
      } else {
        next();
      }
    },
  },
  {
    path: "/sales/orders",
    name: "SalesOrders",
    meta: { ja: "注文リスト" },
    component: () => import("../views/SalesOrders.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.mrchnt_tkn) {
        next({ name: "Login" });
      } else {
        next();
      }
    },
  },
  {
    path: "/profile",
    name: "Profile",
    meta: { ja: "プロフィール" },
    component: () => import("../views/Profile.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.mrchnt_tkn) {
        next({ name: "Login" });
      } else {
        next();
      }
    },
  },
  {
    path: "/profile/update",
    name: "UpdateProfile",
    meta: { ja: "プロファイルを更新" },
    component: () => import("../views/UpdateProfile.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.mrchnt_tkn) {
        next({ name: "Login" });
      } else {
        next();
      }
    },
  },
  {
    path: "/shop",
    name: "ShopList",
    meta: { ja: "お店一覧" },
    component: () => import("../views/ShopList.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.mrchnt_tkn) {
        next({ name: "Login" });
      } else {
        next();
      }
    },
  },
  {
    path: "/shopDetail/:id",
    name: "ShopDetail",
    meta: { ja: "お店詳細" },
    component: () => import("../views/ShopDetail.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.mrchnt_tkn) {
        next({ name: "Login" });
      } else {
        next();
      }
    },
  },
  {
    path: "/createShop",
    name: "CreateShop",
    meta: { ja: "お店追加" },
    component: () => import("../views/CreateShop.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.mrchnt_tkn) {
        next({ name: "Login" });
      } else {
        next();
      }
    },
  },
  {
    path: "/editshop/:id",
    name: "EditShop",
    meta: { ja: "お店編集" },
    component: () => import("../views/EditShop.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.mrchnt_tkn) {
        next({ name: "Login" });
      } else {
        next();
      }
    },
  },
  {
    path: "/others",
    name: "Others",
    meta: { ja: "その他" },
    component: () => import("../views/Others.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.mrchnt_tkn) {
        next({ name: "Login" });
      } else {
        next();
      }
    },
  },
  {
    path: "/createItem",
    name: "CreateItem",
    meta: { ja: "商品追加" },
    component: () => import("../views/CreateItem.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.mrchnt_tkn) {
        next({ name: "Login" });
      } else {
        next();
      }
    },
  },
  {
    path: "/addItemCsv",
    name: "AddItemCsv",
    meta: { ja: "アイテムcsvを追加" },
    component: () => import("../views/AddItemCsv.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.mrchnt_tkn) {
        next({ name: "Login" });
      } else {
        next();
      }
    },
  },
  {
    path: "/editItem/:id",
    name: "EditItem",
    meta: { ja: "商品編集" },
    component: () => import("../views/EditItem.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.mrchnt_tkn) {
        next({ name: "Login" });
      } else {
        next();
      }
    },
  },
  {
    path: "/customerList",
    name: "CustomerList",
    meta: { ja: "顧客リスト" },
    component: () => import("../views/CustomerList.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.mrchnt_tkn) {
        next({ name: "Login" });
      } else {
        next();
      }
    },
  },
  {
    path: "/customer/detail/:id",
    name: "customerDetail",
    meta: { ja: "顧客詳細" },
    component: () => import("../views/CustomerDetail.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.mrchnt_tkn) {
        next({ name: "Login" });
      } else {
        next();
      }
    },
  },
  {
    path: "/shopHoliday",
    name: "ShopHoliday",
    meta: { ja: "休日設定" },
    component: () => import("../views/ShopHoliday.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.mrchnt_tkn) {
        next({ name: "Login" });
      } else {
        next();
      }
    },
  },
  {
    path: "*",
    name: "NotFound",
    meta: { ja: "見つかりません" },
    component: () => import("../views/NotFound.vue"),
  },
  {
    path: "/itemRecomend",
    name: "ItemRecomend",
    meta: { ja: "おすすめの商品" },
    component: () => import("../views/ItemRecomend.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.mrchnt_tkn) {
        next({ name: "Login" });
      } else {
        next();
      }
    },
  },
  {
    path: "/editRecomend",
    name: "EditRecomend",
    meta: { ja: "おすすめ商品編集" },
    component: () => import("../views/EditRecomend.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.mrchnt_tkn) {
        next({ name: "Login" });
      } else {
        next();
      }
    },
  },
  {
    path: "/shopInfo/create",
    name: "CreateShopInfo",
    meta: { ja: "ショップ情報を作成する" },
    component: () => import("../views/about/CreateShopInfo.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.mrchnt_tkn) {
        next({ name: "Login" });
      } else {
        next();
      }
    },
  },


  // NEW ROUTE PAGE DEVELOPMENTV2
  {
    path: "/items",
    name: "ItemListManagement",
    meta: { ja: "商品一覧" },
    component: () => import("../views/developmentv2/ItemListManagement.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.mrchnt_tkn) {
        next({ name: "Login" });
      } else {
        next();
      }
    },
  },
  {
    path: "/marketplace",
    name: "Marketplace",
    meta: { ja: "売り場" },
    component: () => import("../views/developmentv2/Marketplace.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.mrchnt_tkn) {
        next({ name: "Login" });
      } else {
        next();
      }
    },
  },
  {
    path: "/marketplaceGroup",
    name: "MarketplaceGroup",
    meta: { ja: "売り場グループ" },
    component: () => import("../views/developmentv2/MarketplaceGroup.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.mrchnt_tkn) {
        next({ name: "Login" });
      } else {
        next();
      }
    },
  },
  {
    path: "/itemMarketplace",
    name: "ItemMarketplace",
    meta: { ja: "売り場商品配置" },
    component: () => import("../views/developmentv2/ItemMarketplace.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.mrchnt_tkn) {
        next({ name: "Login" });
      } else {
        next();
      }
    },
  },
  {
    path: "/shopNews",
    name: "ShopNews",
    meta: { ja: "店舗からのお知らせ" },
    component: () => import("../views/developmentv2/ShopNews.vue"),
    beforeEnter: (to, from, next) => {
      if (!localStorage.mrchnt_tkn) {
        next({ name: "Login" });
      } else {
        next();
      }
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = `${process.env.VUE_APP_TITLE} - ${to.name}`;
  if (process.env.VUE_APP_I18N_LOCALE == "ja") {
    document.title = `${process.env.VUE_APP_TITLE} - ${to.meta.ja}`;
  }
  next();

  if (to.name === "Login" && localStorage.mrchnt_tkn) {
    next({ name: "OrderReceived" });
  }
  if (
    to.name !== "Login" &&
    !localStorage.mrchnt_tkn &&
    to.name !== "ApplyingMerchant" && to.name !== 'ForgotPassword' && to.name !== 'ResetPassword'
  ) {
    next({ name: "Login" });
  } else {
    next();
  }
});

export default router;
