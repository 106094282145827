import axios from "../../axios/axios";
import router from "../../router";
import moment from "moment";
import shop_module from "./shop_module";
import Swal from "sweetalert2";

const dashboard_module = {
  namespaced: true,
  state: {
    dashboardItem: [],
    filterStoreId: "",
    dashOrderReceived: [],
    dashboardCompleted: [],
    dashboardProcessing: [],
    deliveryDates: [],
    isLoadingReceived: false,
    filterDashboard: {
      initDelivDate: "",
      storeId: "",
    },
    filterOrderReceived: {
      initDelivDate: "",
      storeId: "",
    },
    filterOrderProcess: {
      initDelivDate: "",
      storeId: "",
    },
    filterOrderComplete: {
      initDelivDate: "",
      storeId: "",
    },
    print: {
      status: "",
      delivDate: "",
      storeId: 0,
      orderId: 0,
      invoice_type: ""
    },
    isInitStore: false,
    isInitDatetime: false,
  },
  getters: {
    filteredReceived(state) {
      return state.filterOrderReceived;
    },
    filteredProcess(state) {
      return state.filterOrderProcess;
    },
    filteredComplete(state) {
      return state.filterOrderComplete;
    },
    filter(state) {
      return state.filterDashboard;
    },
    print(state) {
      return state.print;
    },
  },
  mutations: {
    DASHBOARD_ORDER_RECEIVED(state, payload) {
      state.dashOrderReceived = payload;
    },
    DASHBOARD_COMPLETED(state, payload) {
      state.dashboardCompleted = payload;
    },
    DASHBOARD_PROCESSING(state, payload) {
      state.dashboardProcessing = payload;
    },
    DELIVERY_DATE(state, payload) {
      state.deliveryDates = payload;
    },
    FILTER_ORDER_RECEIVED(state, payload) {
      state.filterDashboard.initDelivDate = payload.deliveryDate;
      state.filterDashboard.storeId =
        payload.store_id === undefined ? "" : payload.store_id;
    },
    FILTER_ORDER_PROCESS(state, payload) {
      state.filterDashboard.initDelivDate = payload.deliveryDate;
      state.filterDashboard.storeId =
        payload.store_id === undefined ? "" : payload.store_id;
    },
    FILTER_ORDER_COMPLETE(state, payload) {
      state.filterDashboard.initDelivDate = payload.deliveryDate;
      state.filterDashboard.storeId =
        payload.store_id === undefined ? "" : payload.store_id;
    },
    PRINT(state, payload) {
      state.print.status = payload.status;
      state.print.delivDate =
        payload.deliveryDate == undefined ? "" : payload.deliveryDate;
      state.print.storeId = payload.storeId == undefined ? "" : payload.storeId;
      state.print.orderId = payload.orderId == undefined ? "" : payload.orderId;
      state.print.invoice_type = payload.invoice_type == undefined ? "" : payload.invoice_type;
    },
    INIT_SHOP(state, payload) {
      state.isInitStore = true;
      state.filterDashboard.storeId = payload[0].id;
    },
    INIT_DATETIME(state, payload) {
      state.isInitDatetime = true;
      state.filterDashboard.initDelivDate = payload.delivery_date_value;
    },
  },
  actions: {
    async dashboardOrderReceived(
      { state, getters, commit, dispatch },
      payload
    ) {
      if (payload) {
        commit("FILTER_ORDER_RECEIVED", payload);
      }

      let res = await axios({
        method: "GET",
        url: `/orders/list?store_id=${getters.filter.storeId}&delivery_datetime=${getters.filter.initDelivDate}&status=2`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("mrchnt_tkn")}`,
        },
      })
        .then(({ data }) => {
          state.isLoadingReceived = true;
          commit("DASHBOARD_ORDER_RECEIVED", data.data);
        })
        .catch((err) => {
          if (err.response.status == 401) {
            localStorage.removeItem("mrchnt_tkn");
            router.push("/login");
          }
          commit("DASHBOARD_ORDER_RECEIVED", []);
        })
        .finally(() => {
          state.isLoadingReceived = false;
        });
      return res;
    },
    async dashboardOrderProcessing({ getters, commit }, payload) {
      if (payload) {
        commit("FILTER_ORDER_PROCESS", payload);
      }

      return await axios({
        method: "GET",
        url: `/orders/list?store_id=${getters.filter.storeId}&delivery_datetime=${getters.filter.initDelivDate}&status=3`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("mrchnt_tkn")}`,
        },
      })
        .then(({ data }) => {
          commit("DASHBOARD_PROCESSING", data.data);
        })
        .catch((err) => {
          if (err.response.status == 401) {
            localStorage.removeItem("mrchnt_tkn");
            router.push("/login");
          }
          commit("DASHBOARD_PROCESSING", []);
        });
    },
    async dashboardOrderComplete({ getters, commit }, payload) {
      if (payload) {
        commit("FILTER_ORDER_COMPLETE", payload);
      }
      return await axios({
        method: "GET",
        url: `/orders/list?store_id=${getters.filter.storeId}&delivery_datetime=${getters.filter.initDelivDate}&status=4`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("mrchnt_tkn")}`,
        },
      })
        .then(({ data }) => {
          commit("DASHBOARD_COMPLETED", data.data);
        })
        .catch((err) => {
          if (err.response.status == 401) {
            localStorage.removeItem("mrchnt_tkn");
            router.push("/login");
          }
          commit("DASHBOARD_COMPLETED", []);
        });
    },
    async changeOrderReq({ getters, commit }, payload) {
      return await axios({
        method: "PUT",
        url: `/orders/${payload.orderId}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("mrchnt_tkn")}`,
        },
      })
        .then(async ({ data }) => {
          await this.dispatch("dashboard_module/dashboardOrderReceived", {
            store_id: getters.filter.storeId,
            deliveryDate: getters.filter.initDelivDate,
          });
        })
        .catch((err) => {
          return err;
        });
    },
    async deliveredOrderComplete({ getters, commit }, payload) {
      return await axios({
        method: "PUT",
        url: `/ordercomplete`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("mrchnt_tkn")}`,
        },
        data: payload
      })
        .then(async ({ data }) => {
          await this.dispatch("dashboard_module/dashboardOrderComplete", {
            store_id: getters.filter.storeId,
            deliveryDate: getters.filter.initDelivDate,
          });
        })
        .catch((err) => {
          return err;
        });
    },
    async deliveryDate({ state, commit, getters }, payload) {
      return await axios({
        method: "GET",
        url: `/deliverydates`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("mrchnt_tkn")}`,
        },
      })
        .then(({ data }) => {
          if (!state.isInitDatetime) {
            commit("INIT_DATETIME", data.default);
          } else {
            commit("DELIVERY_DATE", data.data);
          }
        })
        .catch((err) => {
          return err.response;
        });
    },
    async paymentOrder({ commit, getters }, payload) {
      return await axios({
        method: "PUT",
        url: `/payment/orderscharge`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("mrchnt_tkn")}`,
        },
        data: payload,
      })
        .then(async ({ data }) => {
          return await this.dispatch(
            "dashboard_module/dashboardOrderProcessing",
            {
              deliveryDate: getters.filter.initDelivDate,
              store_id: getters.filter.storeId,
            }
          );
        })
        .catch((err) => {
          return err.response;
        });
    },
    async printOrder({ commit, getters }, payload) {
      if (payload) {
        commit("PRINT", payload);
      }
      let print = await axios({
        method: "GET",
        url: `/orderinvoices?status=${getters.print.status}&order_id=${getters.print.orderId}&delivery_date=${getters.print.delivDate}&store_id=${getters.print.storeId}&invoice_type=${getters.print.invoice_type}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("mrchnt_tkn")}`,
        },
      })
        .then(async ({ data }) => {
          if (payload.route === "OrderReceived") {
            await this.dispatch("dashboard_module/dashboardOrderReceived", {
              store_id: getters.filter.storeId,
              deliveryDate: getters.filter.initDelivDate,
            });
          } else if (payload.route === "Completed") {
            await this.dispatch("dashboard_module/dashboardOrderReceived", {
              store_id: getters.filter.storeId,
              deliveryDate: getters.filter.initDelivDate,
            });
          }
          return data;
        })
        .catch((err) => {
          if (err.response.status == 400) {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-right",
              showConfirmButton: false,
              timer: 2500,
              timerProgressBar: false,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });
            Toast.fire({
              icon: "error",
              title: err.response.data.message,
            });
          }
          return err.response;
        });
      return print;
    },
    async shopAll({ state, commit, getters }, payload) {
      if (!state.isInitStore) {
        return await axios({
          method: "GET",
          url: `/stores`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("mrchnt_tkn")}`,
          },
        })
          .then(({ data }) => {
            commit("INIT_SHOP", data.data);
          })
          .catch((err) => {
            if (err.response.status == 401) {
              localStorage.removeItem("mrchnt_tkn");
              router.go("login");
            }
          });
      }
    },
  },
};
export default dashboard_module;
