import axios from "../../axios/axios";
import Swal from "sweetalert2";
import router from "../../router/index"

const shop_module = {
  namespaced: true,
  state: {
    shopListResult: [],
    shopDetail: [],
    resultOthersParams: {},
    resultShopInfo: {},
    totalPage: {},
    pageState: 1,
    shopAllResult: [],
    listNews: [],
    detailShopNews: {},
    shopNewsImage: "",
    initId: 0,
    // store_id: ""
    page: 1,
    length_page: 1,
    is_length: false
  },
  getters: {
    pagination(state){
      return state
    },
    mystore(state) {
      let storeId = state[0].id
      return storeId
    }
  },
  mutations: {
    FETCH_SHOP_LIST(state, payload) {
      state.shopListResult = payload;
    },
    FETCH_SHOP_DETAIL(state, payload) {
      state.shopDetail = payload
    },
    HEADER_SHOP_LIST(state, payload) {
      state.totalPage = payload
      if(payload.page !== undefined) state.pageState = payload.page
      if (payload.total == undefined || payload.per_page == undefined) {
        state.is_length = true
      } else {
        state.is_length = false
        state.length_page = Math.ceil(payload.total / payload.per_page)
      }
    },
    PAGINATION_SHOP (state, payload) {
      // state.page = payload.page == undefined ? 1 : payload.page
      if(payload.page !== undefined) state.pageState = payload.page
    },
    FETCH_SHOP_ALL(state, payload) {
      state.shopAllResult = payload
    },
    INIT_ID(state, payload) {
      state.initId = payload
    },
    
    FETCH_OTHERS_PARAMS(state, payload) {
      state.resultOthersParams = payload
    },

    FETC_SHOP_INFO(state, payload) {
      state.resultShopInfo = payload
    },

    // FILTERED_STORE(state, payload) {
    //   if(payload.store_id != undefined) state.store_id = payload.store_id
    // }

    NEWS_UPLOAD_IMAGE(state, payload) {
      state.shopNewsImage = payload
    },
    FETCH_LIST_NEWS(state, payload) {
      state.listNews = payload
    },
    DELETE_IMAGE_NEWS(state, payload) {
      state.marketplace_image.replace(payload, "")
    },
    CLEAR_IMAGE_SHOP_NEWS(state, payload) {
      state.marketplace_image = ""
    },
    FETCH_DETAIL_SHOP_NEWS(state, payload) {[
      state.detailShopNews = payload
    ]}
  },
  actions: {
    fetchShopList({commit, getters}, payload) {
      if (payload) {
        commit('PAGINATION_SHOP', payload)
      }
      axios({
        method: "GET",
        url: `/store/list?page=${getters.pagination.pageState}`,
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("mrchnt_tkn")}`,
        },
      })
        .then(({ data }) => {
          commit("FETCH_SHOP_LIST", data.data.data);
          commit("HEADER_SHOP_LIST", data.data)
        })
        .catch((err) => {
          if(err.response.status == 401) {
            localStorage.removeItem("mrchnt_tkn");
            router.go('login')
          }
          return err.response.data;
        });
    },
    async fetchShopDetail(context, payload) {
      await axios({
        method: "GET",
        url: `/store/${payload}/detail`,
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("mrchnt_tkn")}`,
        },
      })
        .then(({ data }) => {
          context.commit("FETCH_SHOP_DETAIL", data.data);
        })
        .catch((err) => {
          return err.response.data;
        });
    },
    deleteShop(context, payload) {
      axios({
        method: "GET",
        url: `/store/delete/${payload.payload}`,
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("mrchnt_tkn")}`,
        },
      })
        .then(({ data }) => {
          const Toast = Swal.mixin({
            toast: true,
            position: "top",
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
          Toast.fire({
            icon: "success",
            // title: "data succesfully delete",
            title: payload.message.title
          });
          this.dispatch('shop_module/fetchShopList')
        })
        .catch((err) => {
          return err.response.data;
        });
    },
    async addShopList(context, payload) {
      let resp =  await axios({
        method: 'PUT',
        url: '/store/add',
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('mrchnt_tkn')
        },
        data: payload.payload
      })
      .then((res) => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "success",
          // title: "data succesfully added",
          title: payload.message.title
        });
        return res
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          text: err.response.data.message,
        })
        return err.response
      })
      return resp
    },
    async editShopList(context, payload) {
      let resp = await axios({
        method: 'PUT',
        url: `/store/update/${payload.id}`,
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('mrchnt_tkn')
        },
        data: payload.data
      })
      .then((res) => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "success",
          // title: "data succesfully update",
          title: payload.message.title
        });
      })
      .catch((err) => {
        return err.response;
      })

      return resp
    },
    async fetchShopAll({commit, getters}, payload) {
      return await axios({
        method: "GET",
        url: `/stores`,
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("mrchnt_tkn")}`,
        },
      })
        .then(({ data }) => {
          let init = data.data[0].id
          commit("INIT_ID", init);
          commit("FETCH_SHOP_ALL", data.data);
        })
        .catch((err) => {
          if(err.response.status == 401) {
            
            localStorage.removeItem("mrchnt_tkn");
            router.go('login')
          }
          commit("FETCH_SHOP_ALL", []);
        });
    },
    async getAddressByPostal(context, payload) {
      let url = 'https://apis.postcode-jp.com/api/v4/postcodes?filter=postcode=='+payload.postal
      return await axios({
        url:url,
        method:"GET",
        headers: {
          'apikey': 'VNRQwj8LfTRV41MR41GzeVhS8bTBoFAeWXunFH6'
        }
      })
      .then(({data}) => {
        return data
      })
      .catch(err => {
        return err.response
      })
    },
    async updateOtherParams(context, payload) {
      await axios({
        method: "PUT",
        url: `/store/params/update/`,
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("mrchnt_tkn")}`,
        },
        data: {
          store_id: payload.shop_id,
          delivery_fee: payload.delivery_fee,
          delivery_fee_bulk: payload.delivery_fee_bulk,
          min_qty_bulk_group: payload.min_qty_bulk_group,
          max_bulk_group: payload.max_bulk_group
        }
      })
      .then(({ data }) => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "success",
          // title: "data succesfully update",
          title: data.message
        });
        // this.dispatch("shop_module/fetchOtherParams")
      })
      .catch((err) => {
        return err.response
      })
    },
    async fetchOtherParams({commit, getters}, payload) {
      return await axios({
        method: "GET",
        url: `/store/${payload.store_id}/params`,
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("mrchnt_tkn")}`,
        },
      })
      .then(({ data }) => {
        commit("FETCH_OTHERS_PARAMS", data.data)
      })
      .catch((err) => {
        commit("FETCH_OTHERS_PARAMS", [])
        return err.response.data
      })
    },


    // ABOUT
    async fetchShopInfo(context, payload) {
      await axios({
        method: "GET",
        url: `/storeinfo/${payload}`,
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("mrchnt_tkn")}`,
        },
      })
      .then(({ data }) => {
        context.commit("FETC_SHOP_INFO", data.data)
      })
      .catch((err) => {
        context.commit("FETC_SHOP_INFO", [])
        return err.response.data.message
      })
    },
    createShopInfo(context, payload) {
      axios({
        method: "PATCH",
        url: `/storeinfo/${payload.shop_id}`,
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("mrchnt_tkn")}`,
        },
        data: {
          distributor: payload.seller,
          pic: payload.pic,
          location: payload.location,
          contact: payload.contact,
          public_email: payload.email,
          seeling_price: payload.price,
          fee: payload.fee,
          payment_method: payload.payment_method,
          delivery_time: payload.receive_product,
          correspondence: payload.return_and_cancel
        }
      })
      .then(({ data }) => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "success",
          title: data.message
        });
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          text: err.response.data.message,
        })
        return err.response.message
      })
    },
    createShopNews(context, payload) {
      axios({
        method: "PUT",
        url: "/news/addOrUpdate",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("mrchnt_tkn")}`,
        },
        data: payload
      })
      .then(({ data }) => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "success",
          title: data.message
        });
      })
      .catch((err) => {
        return err
      })
    },
    async fetchShopNews(context, payload) {
      await axios({
        method: "GET",
        url: `/news/list`,
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("mrchnt_tkn")}`,
        },
      })
      .then(({ data }) => {
        context.commit("FETCH_LIST_NEWS", data.data)
      })
      .catch((err) => {
        return err
      })
    },
    async fetchDetailShopNews(context, payload) {
      await axios({
        method: "GET",
        url: `news/detail/${payload}`,
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("mrchnt_tkn")}`,
        },
      })
      .then(({ data }) => {
        context.commit("FETCH_DETAIL_SHOP_NEWS", data.data)
      })
      .catch((err) => {
        context.commit("FETCH_DETAIL_SHOP_NEWS", {})
        return err
      })
    },
    async uploadImageNews(context, payload) {
      let formData = new FormData();
      formData.append("file", payload)
      await axios({
        
        method: "POST",
        url: "/news/uploadImage",
        headers: {
          'Authorization': 'Bearer '+ localStorage.getItem('mrchnt_tkn'),
          "Content-Type": "multipart/form-data",
        },
        data: formData
      })
      .then(({ data }) => {
        // console.log(data.data);
        context.commit("NEWS_UPLOAD_IMAGE", data.data.file)
      })
      .catch((err) => {
        return err
      })
    },
    deleteImageShopNews(context, payload) {
      context.commit("DELETE_IMAGE_NEWS", payload)
    },
    clearStateImageNews(context, payload) {
      context.commit("CLEAR_IMAGE_SHOP_NEWS")
    }
  },
};
export default shop_module;
