import axios from "../../axios/axios";
import router from "../../router";
import Swal from 'sweetalert2'

const auth_module = {
  namespaced: true,
  state: {
    tempEmail: "",
    emailErr: "",
  },
  getters: {},
  mutations: {
    TEMP_REGISTER(state, payload) {
      state.tempEmail = payload;
    },
    EMAIL_ERR(state, payload) {
      state.emailErr = payload;
    },
  },
  actions: {
    login(context, payload) {
      let dataLogin = axios({
        url: "/login",
        method: "POST",
        data: {
          email: payload.email,
          password: payload.password,
        },
      })
        .then(({ data }) => {
          const Toast = Swal.mixin({
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            },
          })

          if(data.response === false){
            Toast.fire({
              icon: 'error',
              title: data.message,
            })
          }else{
            localStorage.setItem("mrchnt_tkn", data.data.token);
            localStorage.setItem('merchant_name', data.data.merchant_name)
            const Toast = Swal.mixin({
              toast: true,
              position: 'top',
              showConfirmButton: false,
              timer: 1000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
              },
            })
            Toast.fire({
              icon: 'success',
              title: payload.message.title,
            }).then(() => {
              router.go('/')
            })
          }
        })
        .catch((err) => {
          return err.response;
        });
      return dataLogin;
    },

    tempRegister(context, payload) {
      context.commit("TEMP_REGISTER", payload);
    },

    // FORGOT PASSWORD
    async cekEmail(context, payload) {
      await axios({
        method: "POST",
        url: '/forgotpassword',
        data: {
          email: payload.email,
          url_redirect: payload.url_redirect
        }
      })
      .then(({ data }) => {
        Swal.fire(data.message)
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          text: err.response.data.message,
          showConfirmButton: false,
          timer: 1000
        });
        return err.response.message
      })
    },

    resetPassword(context, payload) {
      axios({
        method: "POST",
        url: "/resetpassword",
        data: {
          email: payload.email,
          password: payload.password,
          password_confirmation: payload.confirmPassword,
          token: payload.token
        }
      })
      .then(({ data }) => {
        if(data.status == 200) {
          const Toast = Swal.mixin({
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: false,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            },
          })
          Toast.fire({
            icon: 'success',
            title: data.message,
          })
          router.push('/login')
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          text: err.response.data.message,
          showConfirmButton: false,
          timer: 1000
        });
      })
    },

    logout(context, payload) {
      axios({
        url: '/logout',
        method: "POST",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("mrchnt_tkn"),
        },
      })
      .then((res) => {
        localStorage.removeItem("mrchnt_tkn");
        router.go('/login')
      })
      .catch((err) => {
        return err.response
      })
    },
  },
};

export default auth_module;
