import axios from "../../axios/axios";
import Swal from "sweetalert2";
import router from "../../router/index";

const itemList_module = {
  namespaced: true,
  state: {
    itemList: [],
    itemDetail: {},
    itemListCategory: [],
    selectedStore: "",
    selectedStatus: "",
    itemCode: "",
    storeIdform: "",
    itemName: "",
    priceStart: "",
    marketplaceId: "",
    stockTo: "",
    stockFrom: "",
    categoryId: "",
    categoryParentId: "",
    categoryChildId: "",
    categoryGrandChildId: "",
    has_recommended: "",
    priceEnd: "",
    itemListHeader: {},
    categoryId: "",
    storeId: "",
    page: 1,
    length_page: 1,
    is_length: false,
    itemCodeList: [],
    categoryList: [],
    categoryParent: [],
    categoryChild: [],
    categoryGrandChild: [],
    parent_id: 0,
    flag: ""
  },
  getters: {
    filtered(state) {
      return state;
    },
    pagination(state) {
      return state.page;
    },
    filtered_category(state) {
      return state
    }
  },
  mutations: {
    STATE_STORE_ID(state, payload) {
      state.storeIdform = payload
    },
    FETCH_ITEMLIST(state, payload) {
      state.itemList = payload;
    },
    FETCH_ITEMS_DETAIL(state, payload) {
      state.itemDetail = payload;
    },
    FETCH_ITEMLIST_CATEGORY(state, payload) {
      state.itemListCategory = payload;
    },
    SET_STATE_FILTERED(state, payload) {
      console.log(payload, 'payload')
      
    },
    FILTERED_ITEMLIST_V2(state, payload) {
      if (payload.category_parent !== undefined) state.categoryParentId = payload.category_parent
      if (payload.category_child !== undefined) state.categoryChildId = payload.category_child
      if (payload.category_grand_child !== undefined) state.categoryGrandChildId = payload.category_grand_child
      if (payload.page !== undefined) state.page = payload.page;
      if (payload.selected_store !== undefined) state.selectedStore = payload.selected_store;
      if (payload.selected_status !== undefined) state.selectedStatus = payload.selected_status;
      if (payload.item_code !== undefined) state.itemCode = payload.item_code;
      if (payload.item_name !== undefined) state.itemName = payload.item_name;
      if (payload.marketplace_id !== undefined) state.marketplaceId = payload.marketplace_id
      if (payload.stock_from !== undefined) state.stockFrom = payload.stock_from
      if (payload.stock_to !== undefined) state.stockTo = payload.stock_to
    },
    FETCH_ITEMLISTHEADER(state, payload) {
      state.itemListHeader = payload;
      if (payload.total == undefined || payload.per_page == undefined) {
        state.page = payload.current_page;
        state.is_length = true;
        state.page = payload.current_page;
      } else {
        state.is_length = false;
        state.page = payload.current_page;

        state.length_page = Math.ceil(payload.total / payload.per_page);
      }
    },
    FETCH_ITEM_CODE(state, payload) {
      state.itemCodeList = payload;
    },
    PAGINATION(state, payload) {
      state.page = payload.page;
    },
    FETCH_CATGEGORY_LIST(state, payload) {
      state.categoryList = payload;
    },
    
    // MUTATIONS CATEGORY
    FILTERED_CATEGORY(state, payload) {
      if(payload.parent_id !== 0) {

      // filter category child
      let dataChild = state.categoryChild
      let resultCategoryChild = dataChild.filter(el => el.id === payload.parent_id)
      state.categoryChild = resultCategoryChild
      state.parent_id = payload.parent_id
      }
    },
    FETCH_CATEGORY_PARENT(state, payload) {
      
      state.categoryParent = payload
      
    },
    FETCH_CATEGORY_CHILD(state, payload) {
      
      state.categoryChild = payload
    },
    FETCH_CATEGORY_GRAND_CHILD(state, payload) {
      
      state.categoryGrandChild = payload
    },
    SET_STATE_ITEMLIST(state, payload) {
      state.itemList = []
    }
  },
  actions: {
    set_state_filter(context, payload) {
      context.commit("SET_STATE_FILTERED", payload)
    },
    clearStateItemList(context, payload) {
      context.commit("SET_STATE_ITEMLIST", )
    },
    async fetchItemList({ commit, getters }, payload) {
      if(payload) {
        commit("FILTERED_ITEMLIST_V2", payload)
      }
      await axios({
        // url: `/items/list?storeId=${getters.filtered..storeId}&itemName=${getters.filtered.name}&item_code=${getters.filtered.item_code}&storeId=${getters.filtered.selectedStore}&status=${getters.filtered.selectedStatus}&page=${getters.pagination}&priceStart=${getters.filtered.priceStart}&priceEnd=${getters.filtered.priceEnd}&category_id=${getters.filtered.categoryId}&has_recommended=${getters.filtered.has_recommended}`,
        url: `/items/list?itemName=${getters.filtered.itemName}&storeId=${getters.filtered.selectedStore}&item_code=${getters.filtered.itemCode}&status=${getters.filtered.selectedStatus}&page=${getters.pagination}&marketplace=${getters.filtered.marketplaceId}&stockStart=${getters.filtered.stockFrom}&stockEnd=${getters.filtered.stockTo}&category_parent_id=${getters.filtered.categoryParentId}&category_child_id=${getters.filtered.categoryChildId}&category_grandchild_id=${getters.filtered.categoryGrandChildId}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("mrchnt_tkn")}`,
        },
      })
        .then(({ data }) => {
          commit("FETCH_ITEMLIST", data.data.data);
          commit("FETCH_ITEMLISTHEADER", data.data);
        })
        .catch((err) => {
          if (err.response.status == 401) {
            localStorage.removeItem("mrchnt_tkn");
            router.go("login");
          }
          commit("FETCH_ITEMLIST", []);
          commit("FETCH_ITEMLISTHEADER", {});
        });
    },
    insertItemCsv(context, payload) {
      let formData = new FormData();
      formData.append("file", payload.file);
      formData.append("store_id", payload.store_id);
      axios({
        method: "POST",
        url: "/items/import",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("mrchnt_tkn")}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      })
        .then((res) => {
          if (res.status == 200) {
            const Toast = Swal.mixin({
              toast: true,
              position: "top",
              showConfirmButton: false,
              timer: 1500,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });
            Toast.fire({
              icon: "success",
              title: res.data.message,
            });
            setTimeout(() => {
              router.push("/items");
            }, 1500);
          }
        })
        .catch((err) => {
          Swal.fire({
            icon: "warning",
            position: "top",
            text: err.response.data.message,
            showConfirmButton: false,
            timer: 1500,
          });
          return err.response;
        });
    },
    async addItemList(context, payload) {
      let resp = await axios({
        method: "PUT",
        url: "/items/add",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("mrchnt_tkn"),
          "Content-Type": "application/json",
        },
        data: payload,
      })
        .then((res) => {
          if (res.data.status == 200) {
            const Toast = Swal.mixin({
              toast: true,
              position: "top",
              showConfirmButton: false,
              timer: 1500,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });
            Toast.fire({
              icon: "success",
              title: res.data.message,
            });
            // setTimeout(() => {
            //   router.push("/items");
            // }, 1500);
            setTimeout(() => {
              window.location.reload()
            }, 1700)
          }
        })
        .catch((err) => {
          Swal.fire({
            icon: "warning",
            position: "top",
            text: err.response.data.message,
            showConfirmButton: false,
            timer: 1500,
          });
          return err.response;
        });
      return resp;
    },
    async fetchItemListDetail(context, payload) {
      await axios({
        url: `/items/${payload}/detail`,
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("mrchnt_tkn"),
        },
      })
        .then(({ data }) => {
          context.commit("FETCH_ITEMS_DETAIL", data.data);
        })
        .catch((err) => {
          return err.response.data;
        });
    },
    deleteItemList(context, payload) {
      axios({
        url: `/items/delete/${payload.payload}`,
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("mrchnt_tkn"),
        },
      })
        .then((res) => {
          const Toast = Swal.mixin({
            toast: true,
            position: "top",
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
          Toast.fire({
            icon: "success",
            title: payload.message.title,
          });
          context.dispatch("fetchItemList");
        })
        .catch((err) => {
          return err.response.data;
        });
    },
    fetchItemListCategory(context) {
      axios({
        url: "/items/category",
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("mrchnt_tkn"),
        },
      })
        .then(({ data }) => {
          context.commit("FETCH_ITEMLIST_CATEGORY", data.data);
        })
        .catch((err) => {
          context.commit("FETCH_ITEMLIST_CATEGORY", []);
        });
    },
    updateListItem({ commit, getters, dispatch }, payload) {
      axios({
        url: `/items/update/${payload.id}`,
        method: "PUT",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("mrchnt_tkn"),
        },
        data: payload.data,
      })
        .then(async (res) => {
          const Toast = Swal.mixin({
            toast: true,
            position: "top",
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
          Toast.fire({
            icon: "success",
            title: payload.message.title,
          });
          return res;
        })
        .then(() => {
          setTimeout(() => {
            window.location.reload()
          }, 1700)
        })
        .catch((err) => {
          Swal.fire({
            icon: "warning",
            position: "top",
            text: err.response.data.message,
            showConfirmButton: false,
            timer: 1500,
          });
          return err.response.data;
        });
    },
    async setStatusItem(context, payload) {
      await axios({
        method: "PUT",
        url: `/items/setStatus/${payload}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("mrchnt_tkn")}`,
        },
      })
      .then((_) => {
        context.dispatch("fetchItemList");
      })
      .catch((err) => {
        return err
      })
    },
    fetchItemCode({ commit, getters }, payload) {
      axios({
        url: `/items/listItemDropdown`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("mrchnt_tkn")}`,
        },
      })
        .then(({ data }) => {
          commit("FETCH_ITEM_CODE", data.data);
        })
        .catch((err) => {
          if (err.response.status == 401) {
            localStorage.removeItem("mrchnt_tkn");
            router.go("login");
          }
          commit("FETCH_ITEM_CODE", []);
        });
    },
    uploadImageZip({ dispatch, commit, getters }, payload) {
      let formData = new FormData();
      formData.append("file", payload.file);
      formData.append("item_code", payload.item_code);
      axios({
        url: `/items/importImage`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("mrchnt_tkn")}`,
        },
        data: formData,
      })
        .then(({ data }) => {
          dispatch("fetchItemList");
          router.push("/items");
        })
        .catch((err) => {
          if (err.response.status == 401) {
            localStorage.removeItem("mrchnt_tkn");
            router.go("login");
          }
        });
    },
    async categoryList({ commit, getters }, payload) {
      let categoryList = await axios({
        url: `/listCategoriesDropdown`,
        // url: `/categories`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("mrchnt_tkn")}`,
        },
      })
        .then(({ data }) => {
          commit("FETCH_CATGEGORY_LIST", data.data);
        })
        .catch((err) => {
          if (err.response.status == 401) {
            localStorage.removeItem("mrchnt_tkn");
            router.go("login");
          }
          commit("FETCH_CATGEGORY_LIST", []);
        });
      return categoryList;
    },

    // API CATEGORY LIST DROPDOWN
    async fetchCategoryListNew({ commit, getters }, payload) {
      // if(payload) {
      //   commit("FILTERED_CATEGORY", payload)
      // }
      await axios({
        // url: `/category_baru?parent_id=${getters.filtered_category.parent_id}`,
        url: `/category_baru?parent_id=${payload.parent_id}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("mrchnt_tkn")}`,
        },
      })
      .then(({ data }) => {
        // console.log(data);
        // commit("FETCH_CATEGORY_PARENT", data.parent)
        commit("FETCH_CATEGORY_CHILD", data.child)
        commit("FETCH_CATEGORY_GRAND_CHILD", data.grand_child)
      })
      .catch((err) => {
        // commit("FETCH_CATEGORY_PARENT", [])
        commit("FETCH_CATEGORY_CHILD", [])
        commit("FETCH_CATEGORY_GRAND_CHILD", [])
        return err
      })
    },
    async fetchCategoryListParent(context, payload) {
      await axios({
        url: `/parentCategory`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("mrchnt_tkn")}`,
        },
      })
      .then(({ data }) => {
        context.commit("FETCH_CATEGORY_PARENT", data)
      })
      .catch((err) => {
        context.commit("FETCH_CATEGORY_PARENT", [])
        return err
      })
    },
    // async fetchCategoryListChild(context, payload) {
    //   await axios({
    //     url: `/childCategory`,
    //     method: "GET",
    //     headers: {
    //       Authorization: `Bearer ${localStorage.getItem("mrchnt_tkn")}`,
    //     },
    //   })
    //   .then(({ data }) => {
    //     context.commit("FETCH_CATEGORY_CHILD", data)
    //   })
    //   .catch((err) => {
    //     context.commit("FETCH_CATEGORY_CHILD", [])
    //     return err 
    //   })
    // },
    // async fetchCategoryListGrandChild(context, payload) {
    //   await axios({
    //     url: `/grandChildCategory`,
    //     method: "GET",
    //     headers: {
    //       Authorization: `Bearer ${localStorage.getItem("mrchnt_tkn")}`,
    //     },
    //   })
    //   .then(({ data }) => {
    //     context.commit("FETCH_CATEGORY_GRAND_CHILD", data)
    //   })
    //   .catch((err) => {
    //     context.commit("FETCH_CATEGORY_GRAND_CHILD", [])
    //     return err
    //   })
    // },
    async exportItems({ getters, commit }, payload) {
      let exportItems = await axios({
        method: "GET",
        // url: `/items/exportListItem?storeId=${payload.storeId}&status=${payload.status}&itemName=${payload.name}&itemId=${payload.itemId}&category_id=${payload.categoryId}&priceStart=${payload.priceStart}&priceEnd=${payload.priceEnd}&has_recommended=${payload.has_recommended}`,
        url: `/items/exportListItem?storeId=${payload.selected_store}&status=${payload.status}&itemName=${payload.item_name}&stockStart=${payload.stock_from}&stockEnd=${payload.stock_to}&item_code=${payload.item_code}&marketplace=${payload.marketplace_id}&category_parent_id=${payload.category_parent}&category_child_id=${payload.category_child}&category_grandchild_id=${payload.category_grand_child}`,
        headers: {
          // "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("mrchnt_tkn")}`,
        },
        responseType: "blob",
      })
        .then((res) => {
          const url = URL.createObjectURL(
            new Blob([res.data], {
              type: "application/vnd.ms-excel",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          const filename = `商品一覧.csv`;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          return err;
        });
      return exportItems;
    },
    addStateStoreId(context, payload) {
      context.commit("STATE_STORE_ID", payload)
    }
  },
};

export default itemList_module;
