import axios from "../../axios/axios";
// import Swal from "sweetalert2";
import router from "../../router";

const itemRecomend_module = {
  namespaced: true,
  state: {
    itemRecomendList: [],
    itemRecomendHeader: {},
    itemList: [],
    itemHeader: {},
    itemLastPage: {},
    filter: {
      shopId: "",
      item_code: "",
      categoryId: "",
      selectedStatus: "",
      has_recommended: "",
      priceStart: "",
      priceEnd: "",
      name: "",
      page: 1,
      priceEnd: "",
      priceStart: "",
      statusId: "",
      // categoryId: "",
      name: "",
    },
    page: 1,
    length_page: 1,
    is_length: false,
    pageEdit: 1,
    lengthPageEdit: null,
    isLengthEdit: false
  },
  getters: {
    getFilter(state) {
      return state.filter;
    },
    getState(state){
      return state
    },
    pagination(state) {
      return state.page
    },
    getEditFilter(state){
      return state.filter
    }
  },
  mutations: {
    FILTERED_ITEMRECOMEND(state, payload) {
      if (payload.page !== undefined) state.filter.page = payload.page;
      if (payload.page !== undefined) state.page = payload.page;
      if (payload.pageEdit !== undefined)
        state.filter.pageEdit = payload.pageEdit;
      if (payload.shopId !== undefined) state.filter.shopId = payload.shopId;
      if (payload.itemId !== undefined) state.filter.itemId = payload.itemId;
      if (payload.categoryId !== undefined) state.filter.categoryId = payload.categoryId;
      if (payload.name !== undefined) state.filter.name = payload.name;
      if (payload.priceStart !== undefined) state.filter.priceStart = payload.priceStart;
      if (payload.priceEnd !== undefined) state.filter.priceEnd = payload.priceEnd;
      if (payload.selectedStatus !== undefined) state.filter.selectedStatus = payload.selectedStatus
    },
    FILTERED_EDIT_RECOMEND(state, payload) {
      if (payload.page !== undefined) state.page = payload.page;
      if (payload.shopId !== undefined) state.filter.shopId = payload.shopId;
      if (payload.statusId !== undefined)state.filter.statusId = payload.statusId;
      if (payload.item_code !== undefined) state.filter.item_code = payload.item_code;
      if (payload.name !== undefined) state.filter.name = payload.name;
      if (payload.priceStart !== undefined) {state.filter.priceStart = payload.priceStart;
      }
      if (payload.has_recommended !== undefined) state.filter.has_recommended = payload.has_recommended
      if (payload.priceEnd !== undefined) {state.filter.priceEnd = payload.priceEnd;
      }
      if (payload.categoryId !== undefined) {state.filter.categoryId = payload.categoryId;
      }
    },
    SET_ITEMRECOMENDLIST(state, payload) {
      state.itemRecomendList = payload;
    },
    SET_ITEMRECOMENDHEADER(state, payload) {
      state.itemRecomendHeader = payload;

      if (payload.last_page == undefined || payload.per_page == undefined) {
        state.is_length = true
      } else {
        state.is_length = false
        state.length_page = Math.ceil(payload.total / payload.per_page)
      }
    },
    SET_ITEMLIST(state, payload) {
      state.itemList = payload;
    },
    SET_LAST_ITEM(state, payload) {
      state.itemLastPage = payload
    },
    SET_PAGINATION(state, payload) {
      state.itemHeader = payload;      
      if (payload.total == undefined || payload.per_page == undefined) {
        state.is_length = true
        state.page = payload.current_page
      } else {
        state.is_length = false
        state.page = payload.current_page
        state.length_page = Math.ceil(payload.total / payload.per_page)
      }
    },
    CLEAR_FILTERSTATE(state) {
      state.filter.shopId = "";
    },
  },
  actions: {
    fetchItemRecomendList({ getters, commit }, payload) {
      if (payload) {
        commit("FILTERED_ITEMRECOMEND", payload);
      }

      let filter = getters.getFilter;
      axios({
        url: `/items/recommended?itemName=${filter.name}&itemId=${filter.itemId}&status=${filter.selectedStatus}&priceStart=${filter.priceStart}&priceEnd=${filter.priceEnd}&category_id=${filter.categoryId}&storeId=${filter.shopId}&page=${filter.page}`,
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("mrchnt_tkn"),
        },
      })
        .then(({ data }) => {
          commit("SET_ITEMRECOMENDLIST", data.data.data);
          commit("SET_ITEMRECOMENDHEADER", data.data);
        })
        .catch((err) => {
          if (err.response.status == 401) {
            localStorage.removeItem("mrchnt_tkn");
            router.go("login");
          }
          commit("SET_ITEMRECOMENDLIST", []);
          commit("SET_ITEMRECOMENDHEADER", {});
        });
    },
    clearFilter(context) {
      context.commit("CLEAR_FILTERSTATE");
    },
    fetchItemList({ commit, getters }, payload) {
      if (payload) {
        commit("FILTERED_EDIT_RECOMEND", payload);
      }
      let filter = getters.getEditFilter;
      axios({
        // url: `/items/list?page=${getters.getState.page}&storeId=${filter.shopId}`,
        url: `/items/recommendedNew?itemName=${filter.name}&item_code=${filter.item_code}&storeId=${filter.shopId}&status=${filter.statusId}&page=${getters.pagination}&priceStart=${filter.priceStart}&priceEnd=${filter.priceEnd}&category_id=${filter.categoryId}&has_recommended=1`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("mrchnt_tkn")}`,
        },
      })
        .then(({ data }) => {
          commit("SET_ITEMLIST", data.data.data);
          commit("SET_PAGINATION", data.data);
          commit("SET_LAST_ITEM", data.last_item)
        })
        .catch((err) => {
          commit("SET_ITEMLIST", []);
          commit("SET_PAGINATION", {})
          return err.response.data;
        });
    },
    async addItemRecomend(context, payload) {
      let resp = await axios({
        url: `/items/recommended`,
        method: "PUT",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("mrchnt_tkn")}`,
        },
        data: {
          store_id: payload.storeId,
          items: [{ item_id: payload.id }],
        },
      })
        .then(({ data }) => {
          return data;
        })
        .catch((err) => {
          return err.response;
        });

      return resp;
    },
    async putSetUpItemRecomend(context, payload) {
      await axios({
        method: "PUT",
        url: `/items/recommended/setUp/${payload.item_recom_id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("mrchnt_tkn")}`,
        },
      })
      .then(({ res }) => {
        console.log(res);
        this.dispatch("itemRecomend_module/fetchItemList")
      })
      .catch((err) => {
        console.log(err.response.message);
        return err.response.message
      })
    },
    async putSetDownItemRecomend(context, payload) {
      await axios({
        method: "PUT",
        url: `/items/recommended/setDown/${payload.item_recom_id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("mrchnt_tkn")}`,
        },
      })
      .then(({ res }) => {
        console.log(res);
        this.dispatch("itemRecomend_module/fetchItemList")
      })
      .catch((err) => {
        console.log(err.response.message);
        return err.response.message
      })
    },
    async deleteItemRecomend(context, payload) {
      let resp = await axios({
        url: `/items/recommended/${payload.recomend_id}`,
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("mrchnt_tkn")}`,
        },
      })
        .then(({ data }) => {
          return data;
        })
        .catch((err) => {
          return err.response;
        });

      return resp;
    },
  },
};

export default itemRecomend_module;
