import axios from "../../axios/axios";
import Swal from "sweetalert2";
import router from "../../router/index"

const profile_module = {
  namespaced: true,
  state: {
    profileResult: {}
  },
  mutations: {
    FETCH_PROFILE(state, payload) {
      state.profileResult = payload
    }
  },
  getters: {},
  actions: {
    async fetchProfile(context, payload) {
      await axios({
        method: "GET",
        url: "/merchant/profile",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("mrchnt_tkn")}`,
        },
      })
        .then(({ data }) => {
          context.commit("FETCH_PROFILE", data.data);
        })
        .catch((err) => {
          if(err.response.status == 401) {
            localStorage.removeItem("mrchnt_tkn");
            router.go('login')
          }
          return err.response.data;
        });
    },
    updateProfile(context, payload) {
      axios({
        method: "PUT",
        url: "/merchant/profile/update",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("mrchnt_tkn")}`,
        },
        data: payload
      })
      .then(({ data }) => {
        if(data.status == 200) {
          const Toast = Swal.mixin({
            toast: true,
            position: "top",
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
          Toast.fire({
            icon: "success",
            // title: "data succesfully update",
            title: data.message
          });
          setTimeout(() => {
            router.push('/profile')
          }, 1500)
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          text: err.response.data.message,
        })
        return err.response
      })
    }
  },
};
export default profile_module;
