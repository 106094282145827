import axios from '../../axios/axios'
import Swal from "sweetalert2";

const marketplace_module = {
    namespaced: true,
    state: {
        marketplace_list: [],
        marketplace_group_list: [],
        marketplace_image: "",
        marketplaceByStore: [],
        itemsMarketplace: [],
        itemLast_marketplace: {},
        lastItemMarketplaceGroup: {},
    },
    
    mutations: {
        FETCH_MARKETPLACE_BY_STORE(state, payload) {
            state.marketplaceByStore = payload
        },
        FETCH_MARKETPLACE(state, payload) {
            state.marketplace_list = payload
        },
        UPLOAD_IMAGE_MARKETPLACE(state, payload) {
            state.marketplace_image = payload
        },
        DELETE_IMAGE_MARKETPLACE(state, payload) {
            state.marketplace_image.replace(payload, "")
        },
        CLEAR_IMAGE_MARKETPLACE(state, payload) {
            state.marketplace_image = ""
        },
        FETCH_ITEMLAST_MARKETPLACE(state, payload) {
            state.itemLast_marketplace = payload
        },
        FETCH_ITEMS_MARKETPLACE(state, payload) {
            state.itemsMarketplace = payload
        },
        CLEAR_ITEMS_MARKETPLACE(state, payload) {
            state.itemsMarketplace = []
        },
        ADD_STATE_MARKETPLACE(state, payload) {
            let objIndex = state.itemsMarketplace.findIndex(obj => obj.ordered_at == payload.ordered_at)
            state.itemsMarketplace[objIndex].item_id = payload.item_id
            state.itemsMarketplace[objIndex].item_name = payload.item_name
            state.itemsMarketplace[objIndex].image_url = payload.image_url
        },
        SWAP_ITEMS(state, payload) {
            state.itemsMarketplace = []
            state.itemsMarketplace = payload
        },
        DELETE_STATE_ITEMS(state, payload) {
            state.itemsMarketplace = []
            state.itemsMarketplace = payload
        },

        // MUTATION MARKETPLACE GROUP
        LIST_MARKETPLACE_GROUP(state, payload) {
            state.marketplace_group_list = payload;
        },
        LAST_ITEM_MARKETPLACE_GROUP(state, payload) {
            state.lastItemMarketplaceGroup = payload
        } 
    },
    actions: {
        async fetchMarketPlace (context) {
            await axios({
                url: '/marketplace/list',
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('mrchnt_tkn')
                }
            })
            .then(({ data }) => {
                context.commit('FETCH_MARKETPLACE', data.data)
            })
            .catch((err) => {
                context.commit('FETCH_MARKETPLACE', [])
            })
        },
        async addMarketplace(context, payload) {
            await axios({
                method: "POST",
                url: "/marketplace/add",
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('mrchnt_tkn')
                },
                data: payload
            })
            .then((res) => {
                if (res.data.status == 200) {
                  const Toast = Swal.mixin({
                    toast: true,
                    position: "top",
                    showConfirmButton: false,
                    timer: 1500,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                      toast.addEventListener("mouseenter", Swal.stopTimer);
                      toast.addEventListener("mouseleave", Swal.resumeTimer);
                    },
                  });
                  Toast.fire({
                    icon: "success",
                    title: res.data.message,
                  });
                  setTimeout(() => {
                    window.location.reload()
                  }, 1700)
                }
            })
            .catch((err) => {
                Swal.fire({
                    icon: "warning",
                    position: "top",
                    text: err.response.data.message,
                    showConfirmButton: false,
                    timer: 1500,
                });
                return err.response;
            });
        },
        async updateMarketplace(context, payload) {
            await axios({
                method: "PUT",
                url: `/marketplace/update/${payload.id}`,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("mrchnt_tkn"),
                },
                data: payload.tempDataEdit
            })
            .then((res) => {
                if (res.data.status == 200) {
                  const Toast = Swal.mixin({
                    toast: true,
                    position: "top",
                    showConfirmButton: false,
                    timer: 1500,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                      toast.addEventListener("mouseenter", Swal.stopTimer);
                      toast.addEventListener("mouseleave", Swal.resumeTimer);
                    },
                  });
                  Toast.fire({
                    icon: "success",
                    title: res.data.message,
                  });
                  setTimeout(() => {
                    window.location.reload()
                  }, 1700)
                }
            })
            .catch((err) => {
                Swal.fire({
                    icon: "warning",
                    position: "top",
                    text: err.response.data.message,
                    showConfirmButton: false,
                    timer: 1500,
                });
                return err.response;
            });
        },
        listMarketplaceGroup(context, payload) {
            axios({
                method: "GET",
                url: `/marketplace_group/listByStore/${payload}`,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("mrchnt_tkn"),
                },
            })
            .then(({ data }) => {
                context.commit("LIST_MARKETPLACE_GROUP", data.data)
                context.commit("LAST_ITEM_MARKETPLACE_GROUP", data.latest_marketplace)
            })
            .catch((err) => {
                context.commit("LIST_MARKETPLACE_GROUP", [])
                context.commit("LAST_ITEM_MARKETPLACE_GROUP", {})
                return err;
            })
        },
        async addMarketplaceGroup(context, payload) {
            await axios({
                method: "POST",
                url: '/marketplace_group/add',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("mrchnt_tkn"),
                },
                data: payload
            })
            .then((res) => {
                if (res.data.status == 200) {
                  const Toast = Swal.mixin({
                    toast: true,
                    position: "top",
                    showConfirmButton: false,
                    timer: 1500,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                      toast.addEventListener("mouseenter", Swal.stopTimer);
                      toast.addEventListener("mouseleave", Swal.resumeTimer);
                    },
                  });
                  Toast.fire({
                    icon: "success",
                    title: res.data.message,
                  });
                  setTimeout(() => {
                    window.location.reload()
                  }, 1700)
                }
            })
            .catch((err) => {
                Swal.fire({
                    icon: "warning",
                    position: "top",
                    text: err.response.data.message,
                    showConfirmButton: false,
                    timer: 1500,
                });
                return err.response;
            });
        },
        updateMarketplaceGroup(context, payload) {
            axios({
                method: "PUT",
                url: `/marketplace_group/update/${payload.id}`,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("mrchnt_tkn"),
                },
                data: payload.tempDataEdit
            })
            .then((res) => {
                if (res.data.status == 200) {
                  const Toast = Swal.mixin({
                    toast: true,
                    position: "top",
                    showConfirmButton: false,
                    timer: 1500,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                      toast.addEventListener("mouseenter", Swal.stopTimer);
                      toast.addEventListener("mouseleave", Swal.resumeTimer);
                    },
                  });
                  Toast.fire({
                    icon: "success",
                    title: res.data.message,
                  });
                  setTimeout(() => {
                    window.location.reload()
                  }, 1700)
                }
            })
            .catch((err) => {
                Swal.fire({
                    icon: "warning",
                    position: "top",
                    text: err.response.data.message,
                    showConfirmButton: false,
                    timer: 1500,
                });
                return err.response;
            });
        },
        async deleteMarketplaceGroup(context, payload) {
            await axios({
                method: "GET",
                url: `/marketplace_group/delete/${payload.id}`,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("mrchnt_tkn"),
                },
            })
            .then((res) => {
                if (res.data.status == 200) {
                    const Toast = Swal.mixin({
                      toast: true,
                      position: "top",
                      showConfirmButton: false,
                      timer: 1500,
                      timerProgressBar: true,
                      didOpen: (toast) => {
                        toast.addEventListener("mouseenter", Swal.stopTimer);
                        toast.addEventListener("mouseleave", Swal.resumeTimer);
                      },
                    });
                    Toast.fire({
                      icon: "success",
                      title: res.data.message,
                    });
                    this.dispatch("marketplace_module/listMarketplaceGroup", payload.storeId)
                  }
            })
            .catch((err) => {
                return err
            })
        },
        async setUpMarketplaceGroup(context, payload) {
            await axios({
                method: "GET",
                url: `/marketplace_group/setUp/${payload.id}`,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("mrchnt_tkn"),
                },
            })
            .then(() => {
                this.dispatch("marketplace_module/listMarketplaceGroup", payload.storeId)
            })
            .catch((err) => {
                return err
            })
        },
        async setDownMarketplaceGroup(context, payload) {
            await axios({
                method: "GET",
                url: `/marketplace_group/setDown/${payload.id}`,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("mrchnt_tkn"),
                },
            })
            .then(() => {
                this.dispatch("marketplace_module/listMarketplaceGroup", payload.storeId)
            })
            .catch((err) => {
                return err
            })
        },
        async statusMarketplaceGroup(context, payload) {
            await axios({
                method: "GET",
                url: `/marketplace_group/status/${payload.id}`,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("mrchnt_tkn"),
                },
            })
            .then((res) => {
                if (res.data.status == 200) {
                    const Toast = Swal.mixin({
                      toast: true,
                      position: "top",
                      showConfirmButton: false,
                      timer: 1500,
                      timerProgressBar: true,
                      didOpen: (toast) => {
                        toast.addEventListener("mouseenter", Swal.stopTimer);
                        toast.addEventListener("mouseleave", Swal.resumeTimer);
                      },
                    });
                    Toast.fire({
                      icon: "success",
                      title: res.data.message,
                    });
                    this.dispatch("marketplace_module/listMarketplaceGroup", payload.storeId)
                }
            })
            .catch((err) => {
                return err
            })
        },
        async marketplaceByStore({ getters, commit }, payload) {
            let resp = await axios({
                method: "GET",
                url: `/marketplace/listByStore/${payload}`,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("mrchnt_tkn"),
                },
            })
            .then(({ data }) => {
                commit("FETCH_MARKETPLACE_BY_STORE", data.data)
                commit("FETCH_ITEMLAST_MARKETPLACE", data.latest_marketplace)

                return data
            })
            .catch((err) => {
                commit("FETCH_MARKETPLACE_BY_STORE", [])
                commit("FETCH_ITEMS_MARKETPLACE", []);
                return err
            })
            return resp
        },
        async itemsMarketplace(context, payload) {
            await axios({
                method: "GET",
                url: `/marketplace_items/listByMarketplace/${payload}`,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("mrchnt_tkn"),
                },
            })
            .then(({ data }) => {
                context.commit("FETCH_ITEMS_MARKETPLACE", data.data);
            })
            .catch((err) => {
                context.commit("FETCH_ITEMS_MARKETPLACE", []);
                return err
            })
        },
        async addItemsMarketplace(context, payload) {
            await axios({
                method: "POST",
                url: `/marketplace_items/add`,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("mrchnt_tkn"),
                },
                data: payload
            })
            .then((res) => {
                if (res.data.status == 200) {
                    const Toast = Swal.mixin({
                      toast: true,
                      position: "top",
                      showConfirmButton: false,
                      timer: 1500,
                      timerProgressBar: true,
                      didOpen: (toast) => {
                        toast.addEventListener("mouseenter", Swal.stopTimer);
                        toast.addEventListener("mouseleave", Swal.resumeTimer);
                      },
                    });
                    Toast.fire({
                      icon: "success",
                      title: res.data.message,
                    });
                    this.dispatch("marketplace_module/itemsMarketplace", payload.marketplace_id);
                }
            })
            .catch((err) => {
                return err
            })
        },
        addItemStateMarketplace(context, payload) {
            context.commit("ADD_STATE_MARKETPLACE", payload)
        },
        swapItems(context, payload) {
            context.commit("SWAP_ITEMS", payload)
        },
        clearItemsMarketplace(context, payload) {
            context.commit("CLEAR_ITEMS_MARKETPLACE")
        },
        async getStatus(context, payload) {
            await axios({
                method: "GET",
                url: `/marketplace/status/${payload.id}`,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("mrchnt_tkn"),
                },
            })
            .then((res) => {
                if (res.data.status == 200) {
                    const Toast = Swal.mixin({
                      toast: true,
                      position: "top",
                      showConfirmButton: false,
                      timer: 1500,
                      timerProgressBar: true,
                      didOpen: (toast) => {
                        toast.addEventListener("mouseenter", Swal.stopTimer);
                        toast.addEventListener("mouseleave", Swal.resumeTimer);
                      },
                    });
                    Toast.fire({
                      icon: "success",
                      title: res.data.message,
                    });
                    this.dispatch("marketplace_module/marketplaceByStore", payload.storeId)
                }
            })
            .catch((err) => {
                return err
            })
        },
        async deleteMarketplace(context, payload) {
            await axios({
                method: "GET",
                url: `/marketplace/delete/${payload.id}`,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("mrchnt_tkn"),
                },
            })
            .then((res) => {
                if (res.data.status == 200) {
                    const Toast = Swal.mixin({
                      toast: true,
                      position: "top",
                      showConfirmButton: false,
                      timer: 1500,
                      timerProgressBar: true,
                      didOpen: (toast) => {
                        toast.addEventListener("mouseenter", Swal.stopTimer);
                        toast.addEventListener("mouseleave", Swal.resumeTimer);
                      },
                    });
                    Toast.fire({
                      icon: "success",
                      title: res.data.message,
                    });
                    this.dispatch("marketplace_module/marketplaceByStore", payload.storeId)
                  }
            })
            .catch((err) => {
                return err
            })
        },
        async setUpMarketplace(context, payload) {
            await axios({
                method: "GET",
                url: `/marketplace/setUp/${payload.id}`,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("mrchnt_tkn"),
                },
            })
            .then(() => {
                this.dispatch("marketplace_module/marketplaceByStore", payload.storeId)
            })
            .catch((err) => {
                return err
            })
        },
        async setDownMarketplace(context, payload) {
            await axios({
                method: "GET",
                url: `/marketplace/setDown/${payload.id}`,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("mrchnt_tkn"),
                },
            })
            .then(() => {
                this.dispatch("marketplace_module/marketplaceByStore", payload.storeId)
            })
            .catch((err) => {
                return err
            })
        },
        async uploadImage(context, payload) {
            let formData = new FormData();

            formData.append("file", payload)

            await axios({
                method: "POST",
                url: "/marketplace/uploadImage",
                headers: {
                    'Authorization': 'Bearer '+ localStorage.getItem('mrchnt_tkn'),
                    "Content-Type": "multipart/form-data",
                },
                data: formData
            })
            .then(({ data }) => {
                context.commit("UPLOAD_IMAGE_MARKETPLACE", data.data.file);
            })
            .catch((err) => {
                return err
            })
        },
        deleteImageState(context, payload) {
            context.commit("DELETE_IMAGE_MARKETPLACE", payload)
        },
        clearImageMarketplace(context, payload) {
            context.commit("CLEAR_IMAGE_MARKETPLACE")
        },
        deleteStateItem(context, payload) {
            context.commit("DELETE_STATE_ITEMS", payload)
        }
    }
}

export default marketplace_module