import axios from "../../axios/axios";

const shopHoliday_module = {
  namespaced: true,
  state: {
    shopHolidayDetail: {},
    shopActiveDetail: {},
  },
  mutations: {
    FETCH_SHOPHOLIDAY_DETAIL(state, payload) {
      state.shopActiveDetail = payload.activeday;
      state.shopHolidayDetail = payload.holiday;
    },
    CHANGE_DATEHOLIDAY(state, payload) {
      state.shopHolidayDetail.dates[payload.index].date = payload.date;
    },
    CHANGE_DATEACTIVE(state, payload) {
      state.shopActiveDetail.dates[payload.index].date = payload.date;
    },
    ADD_ROWHOLIDAY(state, payload) {
      state.shopHolidayDetail.dates.push({
        id: 0,
        date: null,
      });
    },
    ADD_ROWACTIVE(state, payload) {
      state.shopActiveDetail.dates.push({
        id: 0,
        date: null,
      });
    },
  },
  getters: {},
  actions: {
    async fetchShopHolidayDetail(context, payload) {
      await axios({
        method: "GET",
        url: `/store/${payload.shopId}/holiday`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("mrchnt_tkn")}`,
        },
      })
        .then(({ data }) => {
          context.commit("FETCH_SHOPHOLIDAY_DETAIL", data.data);
        })
        .catch((err) => {
          return err.response.data;
        });
    },
    changeDateHoliday(context, payload) {
      context.commit("CHANGE_DATEHOLIDAY", payload);
    },
    changeDateActive(context, payload) {
      context.commit("CHANGE_DATEACTIVE", payload);
    },
    async addRowHoliday({ commit, state }, payload) {
      //cek state shopholiday lates
      let shopHoliday = state.shopHolidayDetail.dates;
      let current_holiday = shopHoliday[shopHoliday.length - 1];

      if (current_holiday !== undefined) {
        if (
          current_holiday.id == 0 &&
          current_holiday.date != null &&
          payload.shopId
        ) {
          //set payload requyest
          let reqPayload = {
            store_id: payload.shopId,
            holiday: {
              type: 1,
              dates: [current_holiday],
            },
            activeday: {
              type: 1,
              dates: [],
            },
          };

          //fetch add holiday
          let resp = await axios({
            method: "PUT",
            url: `/store/holiday/add`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("mrchnt_tkn")}`,
            },
            data: reqPayload,
          })
            .then(({ data }) => {
              return true;
            })
            .catch((err) => {
              return false;
            });
          return resp;
        } else if (current_holiday.id == 0 && current_holiday.date == null) {
          return null;
        }
      }
      commit("ADD_ROWHOLIDAY");

      return null;
    },
    async addRowActive({ commit, state }, payload) {
      //cek state shopactive lates
      let shopactive = state.shopActiveDetail.dates;
      let current_active = shopactive[shopactive.length - 1];

      if (current_active !== undefined) {
        if (
          current_active.id == 0 &&
          current_active.date != null &&
          payload.shopId
        ) {
          //set payload requyest
          let reqPayload = {
            store_id: payload.shopId,
            holiday: {
              type: 1,
              dates: [],
            },
            activeday: {
              type: 2,
              dates: [current_active],
            },
          };

          //fetch add holiday
          let resp = await axios({
            method: "PUT",
            url: `/store/holiday/add`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("mrchnt_tkn")}`,
            },
            data: reqPayload,
          })
            .then(({ data }) => {
              return true;
            })
            .catch((err) => {
              return false;
            });
          return resp;
        } else if (current_active.id == 0 && current_active.date == null) {
          return null;
        }
      }

      commit("ADD_ROWACTIVE", payload);
      return null;
    },
    async deleteRow(context, payload) {
      let resp = await axios({
        method: "GET",
        url: `/store/holiday/${payload.id}/delete`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("mrchnt_tkn")}`,
        },
      })
        .then(({ data }) => {
          return data;
        })
        .catch((err) => {
          return err.response;
        });
      return resp;
    },
    async updateShopHoliday(context, payload) {
      let resp = await axios({
        method: "PUT",
        url: `/store/holiday/update`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("mrchnt_tkn")}`,
        },
        data: payload,
      })
        .then(({ data }) => {
          return true;
        })
        .catch((err) => {
          return false;
        });
      return resp;
    },
  },
};

export default shopHoliday_module;
