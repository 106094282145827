import axios from '../../axios/axios'
import router from '../../router'
import Swal from 'sweetalert2'

const applyingMerchant_module = {
    namespaced: true,
    state: {
        
    },
    mutations: {

    },
    actions: {
        fetchApplyingMerchant (context, payload) {
            axios({
                method: 'POST',
                url: '/register',
                data: payload
            })
            .then((res) => {
                router.push('/login')
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: payload.message.title,
                    showConfirmButton: false,
                    timer: 1500
                  })
            })
            .catch((err) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'warning',
                    title: err.response.data.message,
                    showConfirmButton: false,
                    timer: 1500
                })
            })
        }
    }
}

export default applyingMerchant_module