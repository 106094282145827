<template>
  <nav>

    <v-toolbar v-if="isMobile" flat>
      <v-app-bar color="indigo" dark app>
        <v-app-bar-nav-icon @click.stop="openDrawer"></v-app-bar-nav-icon>
        <v-spacer></v-spacer>
      </v-app-bar>
    </v-toolbar>

    <v-navigation-drawer
      :permanent="$vuetify.breakpoint.mdOnly"
      v-model="drawer"
      color="indigo"
      dark
      app
      v-if="isLogin"
    >


    <div v-if="!isLogin">
        <v-list-item
          active-class="active"
          style="height: 30px"
        >
          <v-list-item-icon>
            <v-icon size="20" v-text="mdiMenu" style="font-size: 10px"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              <a
              style="text-decoration: none; color:white; font-size: 12px"
              text
              class="mt-5"
              color="white"
            >
              {{ $t('message.menu-sidebar') }}
            </a>
              </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>

      <div v-else>

        <v-list-item
          active-class="active"
          style="height: 30px"
        >
          <v-list-item-icon style="margin-right: 20px; margin-bottom: 5px">
            <v-icon size="20" v-text="mdiStorefront" style="font-size: 30px;"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              <a
              style="text-decoration: none; color:white; font-size: 12px"
              text
              class="mt-5"
              color="white"
            >
              {{ this.mdiMerchant_name }}
            </a>
              </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>

      <div
        v-for="list in drawerList"
        router
        :key="list.text"
        :value="list.active"
        :to="list.route"
      >
        <v-list-group v-if="list.subitems">
          <template v-slot:activator>
            <v-list-item>
              <v-list-item-icon style="margin-right: 20px; margin-bottom: 5px">
                <v-icon size="20" v-text="list.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title style="font-size: 12px" v-text="list.text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <v-list-item
            v-for="subItem in list.subitems"
            :key="subItem.text"
            :to="subItem.route"
            active-class="active"
          >
            <v-list-item-content style="margin-left: 20px; padding: 5px">
              <v-list-item-title  style="font-size: 12px" v-text="subItem.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-item
          v-else
          :to="list.route"
          :click="list.method"
          active-class="active"
        >

            <v-list-item-icon style="margin-right: 20px; margin-bottom: 5px">
              <v-icon size="20" v-text="list.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title style="font-size: 12px">{{ list.text }}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
      </div>
      <!-- LOGOUT/LOGIN-->
      <div v-if="isLogin">

        <v-list-item
          active-class="active"
          style="height: 30px"
          @click.prevent="handleLogout"
        >
          <v-list-item-icon style="margin-right: 20px; margin-bottom: 5px">
            <v-icon size="20" v-text="mdiLogout" style="font-size: 30px"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              <a
              style="text-decoration: none; color:white; font-size: 12px"
              text
              class="mt-5"
              color="white"
            >
              {{$t('message.logout-sidebar')}}
            </a>
              </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-navigation-drawer>
  </nav>
</template>

<script>
import {
  // mdiLoginVariant,
  mdiAccount,
  mdiBasket,
  mdiStorefrontOutline,
  mdiCardBulletedSettings,
  mdiFolderStarMultiple,
  mdiViewDashboard,
  mdiMenu,
  mdiFormatListBulleted,
  mdiLogout,
  mdiStorefront,
  mdiCurrencyUsd,
  mdiInformationVariant
} from "@mdi/js";

export default {
  name: "Sidebar",
  components: {},
  data: () => ({
    // loginIcon: mdiLoginVariant,
    drawer: true,
    isMobile: false,
    drawerLists: [],
    mdiLogout,
    mdiStorefront,
    mdiMenu,
    mdiMerchant_name: localStorage.getItem('merchant_name')
  }),
  methods: {
    openDrawer() {
      this.drawer = !this.drawer;
    },
    handleview() {
      this.isMobile = window.innerWidth <= 960;
    },
    handleLogout() {
      localStorage.removeItem('merchant_name')
      this.$store.dispatch("auth_module/logout", {
        message: {
          title: this.$t('message.logout-success-message'),
          button_ok: this.$t('message.ok')
        }
      });
    },
  },
  beforeDestroy() {
    if (typeof window === "undefined") return;
    window.removeEventListener("resize", this.handleview, { passive: true });
  },
  mounted() {
    this.handleview();
    window.addEventListener("resize", this.handleview, { passive: true });
  },

  computed: {
    mdAndUp() {
      return this.$vuetify.breakpoint.mdAndUp;
    },
    mini() {
      return !(this.mdAndUp || this.menuOpen);
    },
    breakpoint() {
      return JSON.stringify(this.$vuetify.breakpoint, null, 5);
    },
    drawerList() {
      return [
        { icon: mdiViewDashboard, text: this.$t('message.dashboard-sidebar') , route: "/" },
        {
          icon: mdiFolderStarMultiple,
          text: this.$t('message.items-sidebar'),
          subitems: [
            {
              text: this.$t('message.item-list-sidebar'),
              route: "/items",
            },
            // HIDE V1
            // {
            //   text: this.$t('message.stock-sidebar'),
            //   route: "/itemStock",
            // },
            // {
            //   text: this.$t('message.item-recomend-sidebar'),
            //   route: "/editRecomend"
            // },
          ],
        },
        {
          icon: mdiBasket,
          text: this.$t('message.marketplace'),
          subitems: [

            {
              text: "売り場グループ",
              route: "/marketplaceGroup",
            },
            {
              text: this.$t('message.marketplace'),
              route: "/marketplace",
            },
            {
              text: "売り場商品配置",
              route: "/itemMarketplace",
            },
          ],
        },
        { icon: mdiBasket, text: this.$t('message.orders-sidebar'), route: "/orders" },
        { icon: mdiCardBulletedSettings,
          text: this.$t('message.sales-sidebar'),
          subitems: [
            {
              text: this.$t("message.label-order-list"),
              route: "/sales/orders"
            },
            {
              text: this.$t("message.label-order-details"),
              route: "/sales/orderDetails",
            },
          ],
        },
        {
          icon: mdiFormatListBulleted,
          text: this.$t('message.customer-list-sidebar') ,
          route: "/customerList",
        },
        { icon: mdiAccount, text: this.$t('message.profile-sidebar'), route: "/profile" },
        {
          icon: mdiStorefrontOutline,
          text: this.$t('message.shop-sidebar') ,
          subitems: [
            {
              text: "店舗からのお知らせ",
              route: "/shopNews",
            },
            {
              text: this.$t('message.shop-info-sidebar'),
              route: "/shop",
            },
            {
              text: this.$t('message.shop-holiday-sidebar'),
              route: "/shopHoliday",
            },

          ],
        },
        {
          icon: mdiInformationVariant, text: this.$t("message.title-about"), route: '/shopInfo/create'
        },
        {
          icon: mdiCurrencyUsd, text: this.$t("message.title-sidebar-others"), route: '/others'
        }
      ]
    }
  },
  props: {
    isLogin: null
  },
};
</script>

<style scoped>
/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap'); */
.active {
  border-right: 10px solid #1a237e;
}
a div .v-list-item__title {
  color: white;
}
.v-application a {
  color: #1a237e;
}
.v-icon__svg {
  color: white;
}
.v-list-item--active {
  color: #1a237e;
}
.v-list-group__header div .mdi-chevron-down {
  color: white;
}
.v-navigation-drawer__content div a {
  color: transparent;
}
.v-list-group__header .v-list-item {
  padding-left: 0px;
}

/* .style-text {
  font-size: 30px;
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
} */
</style>
