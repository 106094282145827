import axios from "../../axios/axios";

const customer_module = {
  namespaced: true,
  state: {
    customerListResult: [],
    customerResult: {},
    orderResult: [],
    customerHeaderResult: {},
    filter: {
      customerId: "",
      customerName: "",
      amountStart: "",
      amountEnd: "",
      timeuseStart: "",
      timeuseEnd: "",
      lastOrderDate: "",
      limit: 10,
    },
    detailPagination: {
      page: 1,
      length_page: null
    },
    page: 1,
    length_page: 1,
    is_length: false,
    print: {
      customerId: "",
      name: "",
      timeStart: "",
      timeEnd: "",
      amountStart: "",
      amountEnd: "",
      lastOrder: ""
    }
  },
  // getters: {
    
  // },
  mutations: {
    FETCH_CUSTOMER_LIST(state, payload) {
      state.customerListResult = payload;
    },
    FETCH_CUSTOMER_DETAIL(state, payload) {
      state.customerResult = payload.data.customer;
      state.orderResult = payload.data.orders.data
      if (payload.data.orders.total == undefined || payload.data.orders.per_page == undefined) {
        state.is_length = true
        state.detailPagination.page = payload.data.orders.current_page
      } else {
        state.is_length = false
        state.detailPagination.page = payload.data.orders.current_page
        state.detailPagination.length_page = Math.round(payload.data.orders.total / payload.data.orders.per_page)
      }
    },
    PAGINATION_CUSTOMER_DETAIL(state,payload) {
      if (payload != undefined) state.detailPagination.page = payload.page
    },
    FETCH_CUSTOMER_HEADER(state, payload) {
      state.customerHeaderResult = payload;
      if (payload.total == undefined || payload.per_page == undefined) {
        state.is_length = true
        state.page = payload.current_page
      } else {
        state.is_length = false
        state.page = payload.current_page
        state.length_page = Math.ceil(payload.total / payload.per_page)
      }
    },
    FILTERED_CUSTOMER(state, payload) {
      if (payload.page !== undefined) state.page = payload.page;
      if (payload.limit !== undefined) state.filter.limit = payload.limit;
      if (payload.customerId !== undefined)
        state.filter.customerId = payload.customerId;
      if (payload.customerName !== undefined)
        state.filter.customerName = payload.customerName;
      if (payload.amountStart !== undefined)
        state.filter.amountStart = payload.amountStart;
      if (payload.amountEnd !== undefined)
        state.filter.amountEnd = payload.amountEnd;
      if (payload.timeuseStart !== undefined)
        state.filter.timeuseStart = payload.timeuseStart;
      if (payload.timeuseEnd !== undefined)
        state.filter.timeuseEnd = payload.timeuseEnd;
      if (payload.lastOrderDate !== undefined)
        state.filter.lastOrderDate = payload.lastOrderDate;
    },
    PRINT_DELIVERY(state, payload){
      state.print.customerId = payload.customerId == undefined || null ? "" : payload.customerId;
      state.print.name = payload.name == undefined || null ? "" : payload.name;
      state.print.timeStart = payload.timeStart == undefined || null ? "" : payload.timeStart;
      state.print.timeEnd = payload.timeEnd == undefined || null ? "" : payload.timeEnd;
      state.print.amountStart = payload.amountStart == undefined || null ? "" : payload.amountStart;
      state.print.amountEnd = payload.amountEnd == undefined || null ? "" : payload.amountEnd;
      state.print.lastOrder = payload.lastOrder == undefined || null ? "" : payload.lastOrder;
    }
  },
  getters: {
    getFilter(state) {
      return state.filter;
    },
    pagination(state){
      return state
    },
    printByFilter(state){
      return state.print
    },
    detail(state) {
      return state.detailPagination
    },
  },
  actions: {
    fetchCustomerList({ commit, getters }, payload) {      
      if (payload) {
        commit("FILTERED_CUSTOMER", payload);
      }
      let filter = getters.getFilter;
      axios({
        method: "GET",
        url: `/customers?page=${getters.pagination.page}&limit=${filter.limit}&id=${filter.customerId}&name=${filter.customerName}
        &lastOrderDate=${filter.lastOrderDate}&timeuseStart=${filter.timeuseStart}&timeuseEnd=${filter.timeuseEnd}&amountStart=${filter.amountStart}&amountEnd=${filter.amountEnd}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("mrchnt_tkn")}`,
        },
      })
        .then(({ data }) => {
          commit("FETCH_CUSTOMER_LIST", data.data.data);
          commit("FETCH_CUSTOMER_HEADER", data.data);
        })
        .catch((err) => {
          commit("FETCH_CUSTOMER_LIST", []);
          commit("FETCH_CUSTOMER_HEADER", {});
          if (err.response.status == 401) {
            localStorage.removeItem("mrchnt_tkn");
            router.go("login");
          }
          return err.response.data;
        });
    },
    async fetchCustomerDetail({ commit, getters }, payload) {
      if (payload) {
        commit("PAGINATION_CUSTOMER_DETAIL", payload)
      }
      await axios({
        method: "GET",
        url: `/customers/${payload.id}/order?page=${getters.detail.page}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("mrchnt_tkn")}`,
        },
      })
        .then(({ data }) => {
          commit("FETCH_CUSTOMER_DETAIL", data);
        })
        .catch((err) => {
          return err.response.data;
        });
    },
    async printCustomer({ getters, commit }, payload) {      
      if (payload) {
        commit("PRINT_DELIVERY", payload);
      }
      let print = getters.printByFilter
      await axios({
        method: "GET",
        url: `/customers/download?id=${print.customerId}&name=${print.name}&timeuseStart=${print.timeStart}&timeuseEnd=${print.timeEnd}&amountStart=${print.amountStart}&amountEnd=${print.amountEnd}&lastOrderDate=${print.lastOrder}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("mrchnt_tkn")}`,
        },
        responseType: "blob",
      })
        .then((res) => {
          const url = URL.createObjectURL(
            new Blob([res.data], {
              type: "application/vnd.ms-excel",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          const filename = `顧客管理.xlsx`;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();

        })
        .catch((err) => {
          return err
        });          
    },
  },
};
export default customer_module;
