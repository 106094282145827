import axios from "../../axios/axios";
import Swal from 'sweetalert2'

const uploadImage_module = {
  namespaced: true,
  state: {
    resLink: [],
    resLinkImg: "",
  },
  mutations: {
    UPLOAD_IMAGES(state, payload) {
      state.resLink.push({
        id: 0,
        image_url: payload.image_url,
        is_active: 1,
      });
    },
    UPLOAD_IMAGES_SHOP(state, payload) {
      state.resLinkImg = payload;
    },
    DELETE_IMAGES(state, payload) {
      state.resLink.splice(payload, 1);
    },
    CLEAR_IMAGES(state, payload) {
      state.resLink = [];
      state.resLinkImg = "";
    },
    SET_IMAGES(state, payload) {
      let dataImg = payload;
      dataImg.forEach((el, i) => {
        state.resLink.push({
          id: el.id,
          image_url: el.image_url,
          is_active: el.is_active,
        });
      });
    },
  },
  actions: {
    async uploadImg(context, payload) {
      // console.log(payload);
      let formData = new FormData();

      formData.append("file", payload.images);
      formData.append("path", payload.path);
      await axios({
        method: "POST",
        url: "/upload",
        headers: {
          // 'Authorization': 'Bearer '+ localStorage.getItem('mrchnt_tkn'),
          "Content-Type": "multipart/form-data",
        },
        data: formData
      })
      .then(({ data }) => {
        console.log(data.data.file)
        context.commit("UPLOAD_IMAGES", { image_url: data.data.file });
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          text: err.response.data.error.message,
          showConfirmButton: false,
          timer: 1000
        });
        return err
      })
    },
    async upload(context, payload) {

      let formData = new FormData();

      formData.append("file", payload.images);
      formData.append("path", payload.path);

      await axios({
        method: "POST",
        url: "/upload",
        headers: {
          // 'Authorization': 'Bearer '+ localStorage.getItem('mrchnt_tkn'),
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      })
        .then(({ data }) => {
          context.commit("UPLOAD_IMAGES_SHOP", { image_url: data.data.file });
          // context.commit("UPLOAD_IMAGES", { image_url: data.data.file });
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            text: err.response.data.error.message,
            showConfirmButton: false,
            timer: 1000
          });
            return err
        });
    },
    setImg(context, payload) {
      context.commit("CLEAR_IMAGES");
      context.commit("SET_IMAGES", payload);
    },
    clearImg(context, payload) {
      context.commit("CLEAR_IMAGES");
    },
    deleteImgStore(context, payload) {
      axios({
        method: "GET",
        url: `/store/${payload.item_id}/delete/${payload.imgId}/image`,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("mrchnt_tkn"),
        },
      })
      .then(() => {
        context.commit("DELETE_IMAGES", payload.imgIndex);
      })
      .catch((err) => {
        return err
      });
    },
    deleteImgItem(context, payload) {
      axios({
        url: `/items/${payload.item_id}/delete/${payload.imgId}/image`,
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("mrchnt_tkn"),
        },
      })
        .then(() => {
          context.commit("DELETE_IMAGES", payload.imgIndex);
        })
        .catch((err) => {});
    },
    deleteStateItem(context, payload) {
      context.commit("DELETE_IMAGES", payload.index);
    },
    deleteImgBucket(context, payload) {
      axios({
        url: `/deleteImage`,
        method: 'POST',
        headers: {
          Authorization: "Bearer " + localStorage.getItem("mrchnt_tkn"),
        },
        data: {
          path: payload.path
        }
      })
      .then(({ response }) => {
        console.log(response);
      })
      .catch((err) => {
        return err
      })
    }

  },
};

export default uploadImage_module;
