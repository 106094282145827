import axios from "../../axios/axios";
import Swal from "sweetalert2"
const order_module = {
  namespaced: true,
  state: {
    orderListResult: [],
    orderDetailResult: {},
    orderDetails: {},
    orderDetailsHeader: {},
    details: {},
    orderNum: "",
    amountFrom: "",
    amountTo: "",
    dateFrom: "",
    dateTo: "",
    status: "",
    delivery_date: "",
    orderListHeader: {},
    page: 1,
    length_page: 1,
    is_length: false,
    orderDetails: {
      page: 1,
      is_length: false,
      length_page: 1
    }
  },
  getters: {
    filtered(state) {
      return state;
    },
  },
  mutations: {
    FETCH_ORDER_LIST(state, payload) {
      state.orderListResult = payload;
    },
    FETCH_ORDER_DETAIL(state, payload) {
      state.orderDetailResult = payload;
      state.orderDetails = payload.orders
      state.details = payload.orders.detail
    }, 
    FETCH_HEADER_ORDERS_DETAILS(state, payload) {
      state.orderDetailsHeader = payload
      // console.log(state.orderDetailsHeader, 'state');
      if(payload.total == undefined || payload.total == 0 || payload.per_page == undefined) {
        state.orderDetails.is_length = true
        state.orderDetails.page = payload.current_page
      } else {
        state.orderDetails.is_length = false
        state.orderDetails.page = payload.current_page
        state.orderDetails.length_page = Math.ceil(payload.total / payload.per_page)
      }
    },
    FILTERED_ORDER(state, payload) {
      if (payload.orderNum !== undefined) state.orderNum = payload.orderNum;
      if (payload.amountFrom !== undefined)
        state.amountFrom = payload.amountFrom;
      if (payload.amountTo !== undefined) state.amountTo = payload.amountTo;
      if (payload.dateFrom !== undefined) state.dateFrom = payload.dateFrom;
      if (payload.dateTo !== undefined) state.dateTo = payload.dateTo;
      if (payload.statusItems !== undefined) state.status = payload.statusItems;
      if (payload.delivery_date !== undefined) state.delivery_date = payload.delivery_date
      if (payload.page !== undefined) state.page = payload.page;
      // if (payload.page === undefined) {
      //   state.is_length = true
      //   state.page = state.page
      //   state.length_page = state.length_page
      // }
      
    },
    FETCH_ORDER_LIST_HEADER(state, payload) {
      state.orderListHeader = payload;
      if (payload.total == undefined || payload.per_page == undefined) {
        state.is_length = true
        state.page = payload.current_page
      } else {
        state.is_length = false
        state.page = payload.current_page
        state.length_page = Math.ceil(payload.total / payload.per_page)
      }
    },
    CLEAR_FILTERSTATE(state) {
      state.orderNum = "";
      state.amountFrom = "";
      state.amountTo = "";
      state.dateFrom = "";
      state.dateTo = "";
      state.status = "";
      state.page = "";
    },
  },
  actions: {
    fetchOrderList({ commit, getters }, payload) {
      if (payload) {
        commit("FILTERED_ORDER", payload);
      }

      let listOfOrder = axios({
        method: "GET",
        url: `/orders?amountStart=${getters.filtered.amountFrom}&amountEnd=${getters.filtered.amountTo}&delivery_date=${getters.filtered.delivery_date}&orderno=${getters.filtered.orderNum}&datefrom=${getters.filtered.dateFrom}&dateto=${getters.filtered.dateTo}&status=${getters.filtered.status}&page=${getters.filtered.page}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("mrchnt_tkn")}`,
        },
      })
        .then(({ data }) => {
          commit("FETCH_ORDER_LIST", data.data.data);
          commit("FETCH_ORDER_LIST_HEADER", data.data);
        })
        .catch((err) => {
          if (err.response.status == 401) {
            localStorage.removeItem("mrchnt_tkn");
            router.go("login");
          }
          commit("FETCH_ORDER_LIST", []);
          commit("FETCH_ORDER_LIST_HEADER", {});
        });
      return listOfOrder;
    },
    async fetchOrderDetail({commit, getters}, payload) {
      // console.log(payload, 'payload');
      await axios({
        method: "GET",
        url: `/orders/${payload.id}?page=${payload.page}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("mrchnt_tkn")}`,
        },
      })
      .then(({ data }) => {
        // console.log(data.data);
        commit("FETCH_ORDER_DETAIL", data.data)
        commit("FETCH_HEADER_ORDERS_DETAILS", data.data.orders.detail)
      })
      .catch((err) => {
        commit("FETCH_ORDER_DETAIL", {})
        commit("FETCH_HEADER_ORDERS_DETAILS", {})
        return err.response
      })
    },
    printOrder(context, payload) {
      axios({
        method: "GET",
        url: `/orderinvoices?invoice_type=${payload.type}&order_id=${payload.orderId}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("mrchnt_tkn")}`,
        },
      })
      .then(({ data }) => {
        window.open(data.redirect_url, "_blank");
      })
      .catch((err) => {
        return err.response
      })
    },
    clearFilter(context) {
      context.commit("CLEAR_FILTERSTATE");
    },
  },
};

export default order_module;
