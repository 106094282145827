import Vue from "vue";
import Vuex from "vuex";
import auth_module from './modules/auth_module'
import order_module from './modules/order_module'
import customer_module from './modules/customer_module'
import shop_module from './modules/shop_module'
import itemList_module from './modules/itemList_module'
import uploadImage_module from './modules/uploadImage_module'
import profile_module from '@/store/modules/profile_module'
import dashboard_module from '@/store/modules/dashboard_module'
import itemStock_module from '@/store/modules/itemStock_module'
import shopHoliday_module from '@/store/modules/shopHoliday_module'
import applyingMerchant_module from './modules/applyingMerchant_module'
import itemRecomend_module from '@/store/modules/itemRecomend_module'
import sales_module from '@/store/modules/sales_module';
import marketplace_module from '@/store/modules/marketplace_module';

Vue.use(Vuex);


export default new Vuex.Store({
  modules: {
    itemList_module,
    auth_module,
    order_module,
    customer_module,
    shop_module,
    uploadImage_module,
    profile_module,
    profile_module,
    dashboard_module,
    itemStock_module,
    shopHoliday_module,
    applyingMerchant_module,
    itemRecomend_module,
    sales_module,
    marketplace_module
  }
})
