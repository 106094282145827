<template>
  <v-app>
    <SideBar :isLogin="getToken" />
      <v-main class="main-class">
        <router-view/>
      </v-main>
  </v-app>
</template>

<script>
// import SideBar from '@/components/SideBar.vue'
import SideBar from './components/SideBar.vue';

export default {
  name: 'App',
  components: {
    SideBar
  },

  data: () => ({
    //
  }),
  computed: {
    getToken() {
      return localStorage.getItem('mrchnt_tkn')
    }
  }
};
</script>

<style>
.v-application--wrap .main-class {
  padding-top: 0px !important;
}
.v-application--is-ltr .v-data-footer__select {
  margin-left: 0px !important;
}
</style>
