import axios from "../../axios/axios";
import router from "../../router/index";

const shop_module = {
  namespaced: true,
  state: {
    salesResult: [],
    productResult: [],
    storeId: "",
    dateFrom: "",
    dateTo: "",
    dateType: "",
    initId: "",
    salesOrder: [],
    salesOrderList: []
  },
  getters: {
    filtered(state) {
      return state;
    },
    initVal(state) {
      return state;
    },
  },
  mutations: {
    FETCH_SALES(state, payload) {
      state.salesResult = payload;
    },
    FETCH_PRODUCTS(state, payload) {
      state.productResult = payload;
    },
    FILTER(state, payload) {
      if (payload.storeId !== undefined) state.storeId = payload.storeId;
      if (payload.dateFrom !== undefined) state.dateFrom = payload.dateFrom;
      if (payload.dateTo !== undefined) state.dateTo = payload.dateTo;
      if (payload.selectStatus !== undefined)
        state.dateType = payload.selectStatus;
    },
    INIT(state, payload) {
      state.initId = payload;
    },
    SALES_ORDER(state, payload) {
      state.salesOrder = payload
    },
    SALES_ORDERS_LIST(state, payload) {
      state.salesOrderList = payload
    }
  },
  actions: {
    async fetchSales({ commit, getters, rootState }, payload) {
      if (payload) {
        commit("FILTER", payload);
      }
      return await axios({
        method: "GET",
        url: `/report/so?store_id=${getters.filtered.storeId}&datefrom=${getters.filtered.dateFrom}&dateto=${getters.filtered.dateTo}&type=${getters.filtered.dateType}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("mrchnt_tkn")}`,
        },
      })
        .then(({ data }) => {
          commit("FETCH_SALES", data.data.sales);
          commit("FETCH_PRODUCTS", data.data.products);
          // dispatch('shop_module/fetchShopAll', null, { root: true } )
        })
        .catch((err) => {
          commit("FETCH_SALES", err.response.data.data.sales);
          commit("FETCH_PRODUCTS", err.response.data.data.products);
          // return err.response.data;
        });
    },
    async fetchSalesOrders({ commit, getters }, payload) {
      return await axios({
        method: "GET",
        url: `/report/orders`,
        params: payload,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("mrchnt_tkn")}`,
        },
      })
      .then(({ data }) => {
        commit("SALES_ORDERS_LIST", data.data)
      })
      .catch((err) => {
        if (err.response.status == 404) {
          commit("SALES_ORDERS_LIST", []);
        }
        return err
      })
    },
    async exportCsvSalesOrders(context, payload) {
      let exportItems = await axios({
        method: "GET",
        url: `/report/exportOrders`,
        params: payload,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("mrchnt_tkn")}`,
        },
        responseType: "blob",
      })
        .then((res) => {
          const url = URL.createObjectURL(
            new Blob([res.data], {
              type: "application/vnd.ms-excel",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          const filename = `売上.csv`;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          return err;
        });
      return exportItems;
    },
    async fetchSalesOrderDetails({ commit, getters, rootState }, payload) {
      return await axios({
        method: "GET",
        url: `/report/orderDetails`,
        params: payload,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("mrchnt_tkn")}`,
        },
      })
        .then(({ data }) => {
          commit("SALES_ORDER", data.data);
        })
        .catch((err) => {
          if (err.response.status == 404) {
            commit("SALES_ORDER", []);
          }
          return err
        });
    },
    async exportCsv({ getters, commit }, payload) {
      let exportItems = await axios({
        method: "GET",
        // url: `/report/orderDetails`,
        url: `/report/exportOrderDetails`,
        params: payload,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("mrchnt_tkn")}`,
        },
        responseType: "blob",
      })
        .then((res) => {
          const url = URL.createObjectURL(
            new Blob([res.data], {
              type: "application/vnd.ms-excel",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          const filename = `売上.csv`;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          return err;
        });
      return exportItems;
    },
  },
};
export default shop_module;
