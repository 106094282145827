import axios from "../../axios/axios";
import Swal from "sweetalert2";
import router from "../../router/index"
import i18n from "../../i18n"

const itemStock_module = {
  namespaced: true,
  state: {
    itemStockList: [],
    itemStockHeader: {},
    addItemStock: [],
    filter: {
      filterStock: 1,
      filterStatus: "1",
    },
    page: 1,
    length_page: 1,
    is_length: false
  },
  getters: {
    pagination(state){
      return state.page
    },
    filter(state){
      return state.filter
    }
  },
  mutations: {
    FETCH_ITEMSTOCKLIST(state, payload) {
      state.itemStockList = payload;
    },
    FETCH_ITEMSTOCKHEADER(state, payload) {
      state.itemStockHeader = payload;
      if (payload.total == undefined || payload.per_page == undefined) {
        state.is_length = true
      } else {
        state.is_length = false
        state.length_page = Math.ceil(payload.total / payload.per_page)
      }
    },
    UPDATE_ITEMSTOCK(state, payload) {
      //find item by key
      let itemStock = state.itemStockList[payload.key];
      let addItemStock = state.addItemStock;
      let currentAddStock = 0;

      if (payload.operator == "+") {
        let index = addItemStock.findIndex((x) => x.id === payload.id);

        if (index == -1) {
          currentAddStock = 1;
          state.addItemStock.push({ id: payload.id, qty: currentAddStock });
        } else {
          currentAddStock = addItemStock[index].qty + 1;
          state.addItemStock[index].qty = currentAddStock;
        }
      } else if (payload.operator == "-") {
        let index = addItemStock.findIndex((x) => x.id === payload.id);
        if (index == -1) {
          currentAddStock = -1;
          state.addItemStock.push({ id: payload.id, qty: currentAddStock });
        } else {
          currentAddStock = addItemStock[index].qty - 1;
          // if (currentAddStock < 0) {
          //   currentAddStock = 0;
          // }
          state.addItemStock[index].qty = currentAddStock;
          
        }
      } else if (payload.operator == "custom") {
        let index = addItemStock.findIndex((x) => x.id === payload.id);

        if (index == -1) {
          if (payload.value != "") {
            itemStock = parseInt(payload.value);
            state.addItemStock.push({ id: payload.id, qty: itemStock });
          } else {
            itemStock = 0;
            state.addItemStock.push({ id: payload.id, qty: itemStock });
          }
        } else {
          if (payload.value != "") {
            itemStock = parseInt(payload.value);
            state.addItemStock[index].qty = itemStock;
          } else {
            itemStock = 0;
            state.addItemStock[index].qty = itemStock;
          }
        }
      }
    },
    CLEAR_ITEMSTOCK(state) {
      state.addItemStock = [];
    },
    FILTER(state,payload){
      state.filter.filterStock = payload.stock == undefined ? "" : payload.stock
      state.filter.filterStatus = payload.status == undefined ? "" : payload.status
    },
    PAGINATION(state,payload){
      state.page = payload.page == undefined ? "" : payload.page 
    },
  },
  actions: {
    fetchItemStockList({getters, commit}, payload) {
      if (payload) {
        commit("FILTER", payload)
        commit("PAGINATION", payload)
      }
      axios({
        url: `/items/list?without_itembulk=true&status=${getters.filter.filterStatus}&page=${getters.pagination}&itemStock=${getters.filter.filterStock}`,
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("mrchnt_tkn"),
        },
      })
        .then(({ data }) => {

          commit("FETCH_ITEMSTOCKLIST", data.data.data);
          commit("FETCH_ITEMSTOCKHEADER", data.data);
        })
        .catch((err) => {
          if(err.response.status == 401) {
            localStorage.removeItem("mrchnt_tkn");
            router.go('login')
          }
          commit("FETCH_ITEMSTOCKLIST", []);
          commit("FETCH_ITEMSTOCKHEADER", {});
        });
    },
    updateStockItem(context, payload) {
      context.commit("UPDATE_ITEMSTOCK", payload);
    },
    updateStock({ commit, getters, state }, payload) {
      axios({
        url: `/item-stock/store/0`,
        method: "PATCH",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("mrchnt_tkn"),
        },
        data: {
          data: payload.payload,
        },
      })
        .then(({ data }) => {
          const Toast = Swal.mixin({
            toast: true,
            position: "top",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: false,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
          Toast.fire({
            icon: "success",
            title: i18n.t('message.update-stock-success'),
          });

          this.dispatch("itemStock_module/fetchItemStockList", {
            status: getters.filter.filterStatus,
            stock: getters.filter.filterStock,
            page: getters.pagination
          });
          commit("CLEAR_ITEMSTOCK");
        })
        .catch((err) => {
          return err.response
        });
    },
    resetStockItem({ commit, getters, state }, payload) {
      axios({
        url: `/item/resetStock`,
        method: "PATCH",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("mrchnt_tkn"),
        },
        // data: {
        //   data: payload.payload,
        // },
      })
        .then(({ data }) => {
          this.dispatch("itemStock_module/fetchItemStockList", {
            status: getters.filter.filterStatus,
            stock: getters.filter.filterStock,
            page: getters.pagination
          });
          commit("CLEAR_ITEMSTOCK");
        })
        .catch((err) => {
          return err.response
        });
    },
  },
};

export default itemStock_module;
